import React from 'react'

function GridTile(props) {

    const gridValues = props.screenWidth === 'desktop' ? 
        { gridColumn: props['lg-columns'], gridRow: props['lg-rows'] } :
        { gridColumn: props['sm-columns'] ? props['sm-columns'] : 'span 6', gridRow: props['sm-rows'] }
    
    return ( 
            <div className={`grid-tile ${props.className || ''}`} style={gridValues}>
                {props.children}
            </div>
    )
}

export default GridTile
