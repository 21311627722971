export default function ObjectObserver(o, property) {
    var _this = this;
    this.value = o[property];
    this.observers = [];
    this.observe = function (notifyCallback) {
        _this.observers.push(notifyCallback);
    };

    Object.defineProperty(o, property, {
        configurable: true,
        set: function (val) {
            _this.value = val;
            for (var i = 0; i < _this.observers.length; i++)
                _this.observers[i](val);
        },
        get: function () {
            return _this.value;
        },
    });
}