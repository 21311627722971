import React from 'react'

function Account(props) {
    return (
        <div {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 31 33" fill="none">
            <circle cx="15.9833" cy="8.21667" r="7.71667" stroke="black"/>
            <path d="M30 32.8665C30 24.8584 23.5081 18.3665 15.5 18.3665C7.49187 18.3665 1 24.8584 1 32.8665" stroke="black"/>
            </svg>
        </div>
    )
}

export default Account
