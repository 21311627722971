import React, { useState, useEffect, useRef } from 'react'
// import Chevron from '../icons/chevron';
import Preferences from '../icons/preferences';
import Account from '../icons/account';
import ProgressAnim from '../progress-animation/ProgressAnimation'
import Hamburger from '../icons/hamburger';

function PreLogInNav() {
    const [isNavDropdownOpen, setIsNavDropdownOpen] = useState(false);
    const navDropdownRef = useRef(null);

    useEffect(() => {
        if (isNavDropdownOpen) {
            window.addEventListener("click", function closeNavDropdown(event) {
                if (!navDropdownRef.current.contains(event.target)) {
                    setIsNavDropdownOpen(false);
                    window.removeEventListener("click", closeNavDropdown);
                }
            });
        }
    })

    return (
        <div className="pre-log-in-nav">
            <div className="nav">
                <div className="nav-content">
                    <div className="hamburger">
                        <Hamburger color={ '#1373E6' } />
                    </div>
                    <div className="left-nav">
                        <div className="nav-item">About</div>
                        <div className="nav-item">Learning</div>
                        <div className="nav-item">Teaching</div>
                        <div className="nav-item">Public Schools</div>
                        <div className="nav-item">Support</div>
                    </div>
                    <div className="right-nav">
                        <div className="nav-item">
                            <input type="text" placeholder=" Search" className="nav-search"></input>
                        </div>
                        <div className="nav-item icon" onClick={() => setIsNavDropdownOpen(true)}><Preferences />Preferences
                            <ProgressAnim>
                                <h2>Adobe Target</h2>
                                <p>
                                    In an anonymous environment users can still enjoy a tailored experience through preference setting. With selections made, Adobe Target can surface relevant content based on the user's inputs.
                                </p>
                            </ProgressAnim>
                            </div>
                        <div className="nav-item icon"><Account />Account</div>
                    </div>
                </div>
                <div ref={navDropdownRef} className={`nav-dropdown ${!isNavDropdownOpen ? 'hide' : ''}`}>
                    <div className="nav-dropdown__header">
                        <Account className="nav-dropdown__icon" />
                        <div className="nav-dropdown__copy">
                            <h3>Select all the topics you're interested in.</h3>
                            <p>Don't worry, you can change these later in Preferences.</p>
                        </div>
                    </div>
                    <div className="nav-dropdown__topics-container">
                        <div className="nav-dropdown__topic active">Primary Education</div>
                        <div className="nav-dropdown__topic">School Sport</div>
                        <div className="nav-dropdown__topic active">Curriculum</div>
                        <div className="nav-dropdown__topic">Creative Arts</div>
                        <div className="nav-dropdown__topic">Secondary Education</div>
                        <div className="nav-dropdown__topic">Student Wellbeing</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreLogInNav;
