import React, { useEffect } from 'react';
import ColorMap from '../../data/colorMap.json'

function LightboxToolTip(props, ref) {
    const [persona] = window.location.pathname.split('/').filter(value => !!value);
    let borderLeftColor = persona in ColorMap ? ColorMap[persona] : '#1373E6';

    const { content, progresscb, stepindex, hotspot, faas, lightboxvisible, large, toggletour, onOutstideClick} = props

    const cleanupHighlightedEls = () => {
        if (Array.from(document.querySelectorAll('.lightbox-highlight')).length > 0) {
            Array.from(document.querySelectorAll('.lightbox-highlight')).forEach(el => el.classList.remove('lightbox-highlight', 'true'))
        }
    }

    const exitTourAndCleanup = () => {
        cleanupHighlightedEls()
        toggletour(false)
    }

    const assignCSSPosValues = (el) => {
        let targetElPos = document.querySelector(el.selector).getBoundingClientRect();
            document.querySelector('.positioned').style = el.position === "right" ? 
            `  
                --left: ${ (targetElPos.right + 25) + 'px'};
                --top: ${ targetElPos.top + 'px' };
                --flex: row
            ` :
            `
                --left: ${ targetElPos.width / 2 - 150 + targetElPos.left + 'px' };
                --top: ${ (targetElPos.bottom + 25) + 'px' };
                --flex: column
            `
    }

    let stepContent;
    if (content) stepContent = content[stepindex];

    if (stepContent && lightboxvisible) {

        if (stepContent.openDrawer || stepContent.closeDrawer) document.querySelector('.tech-drawer-bar').click()

        let targetEl = document.querySelector(stepContent.selector);
        if (stepContent.selector) targetEl.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"})
        
        cleanupHighlightedEls()

        if (stepContent.selector) targetEl.classList.add('lightbox-highlight')
        if (stepContent.zindex) targetEl.classList.add('true')
    }

    useEffect(() => {
        if (stepContent && stepContent.highlight) {
            if (!stepContent.zindex) assignCSSPosValues(stepContent)
        }
    }, [stepContent])

    return (
        stepContent ? 
            <div ref={ref} className={ stepContent.highlight ? "positioned tt-container": "tt-container"}>
                <div className={ 
                    stepContent.highlight && stepContent.position === "right" ? 
                        "caret right" : 
                        stepContent.highlight ? "caret bottom" :
                        ""
                    }>
                </div>
                
                <div style={{ borderLeftColor }} className={ stepContent.large ? "lightbox-tooltip large tour-tt" : stepContent.bump ? "lightbox-tooltip bump tour-tt" : "lightbox-tooltip tour-tt" }>
                    <div className="dismissal-bar" onClick={() => exitTourAndCleanup()}>×</div>
                    <h2>{ stepContent.heading }</h2>
                    <p>{ stepContent.copy }</p>
                    <button onClick={() => progresscb ? progresscb(stepindex + 1) : ''}>{ stepContent.buttonText }</button>
                    { stepContent.large ? <p className="skip-tt" onClick={() => exitTourAndCleanup()}>Skip Instructions</p> : "" }
                    <div className="tour-dot-container">
                        {
                            content.map( (val, i) => 
                                <div key={i} className={ i === stepindex ? "tour-dot active" : "tour-dot" } onClick={() => progresscb(i)}></div>
                            )
                        }
                    </div>
                </div>
            </div>
            :
            <div ref={ref} style={{ borderLeftColor }} className={ large ? "lightbox-tooltip large" : hotspot ? "lightbox-tooltip progression-animation hotspot" : faas ? "" : "lightbox-tooltip" }>
                { hotspot ? <div className="dismissal-bar" onClick={onOutstideClick}>×</div> : "" }
                { props.children }
                { hotspot ? <p className="hotspot-resume" onClick={onOutstideClick}>Resume Demo</p> : "" }
            </div>
    )
};

export default React.forwardRef(LightboxToolTip)
