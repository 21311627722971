import React, { useState } from 'react'
import Link from '../with-last-visited/Link';
import ReactDOM from 'react-dom';
import TechDrawerInfo from '../icons/tech-drawer-info';
import Eye from '../icons/eye'
import TechDrawerChevron from '../icons/tech-drawer-chevron';
import JourneySelector from '../journey-utils/JourneySelector';
import Lightbox from '../lightbox/Lightbox';
import Button from '../button/Button'

function TechDrawer(props) {
    const [lightboxVisible, setLightboxVisible] = useState(false);

    const [techDrawer, openTechDrawer] = useState(false);

    const journey = window.location.pathname === "/" ? 
            "unknown" :
            window.location.pathname.substring(1).split('/')[0];

    const fireTracking = () => {
        const intervalId = setInterval(() => {
            if (window.digitalData) {
                clearInterval(intervalId);
    
                window.digitalData._set( 
                    'digitalData.primaryEvent.eventInfo.eventName', `Contact Us Button Click & Exit` 
                ); 
                window._satellite.track('event', { digitalData: window.digitalData._snapshot() }); 
                window.digitalData._delete('digitalData.primaryEvent.eventInfo.eventName'); 
            }
        }, 100)
    }

    return ReactDOM.createPortal(
        <div className="tech-drawer">
            <Lightbox isvisible={lightboxVisible} className="begin-intro-confirm__container" large onOutsideClick={() => setLightboxVisible(false)}>
                <div className='begin-intro-confirm'>
                    <h2>View the intro walkthrough again?</h2>
                    <p>This will lead you back to the homepage and you may lose your progression. Are you sure?</p>
                    <Button className="begin-intro-confirm__button" primary onClick={() => setLightboxVisible(false)}>No</Button>
                    <Link to='/' onClick={() => {
                        setLightboxVisible(false);
                        openTechDrawer(false);
                        window.sessionStorage.setItem("tourSeen", "")
                    }}><Button className="begin-intro-confirm__button" secondary>Yes</Button></Link>
                </div>
            </Lightbox>
            <div className="tech-drawer-bar" onClick={() => openTechDrawer(!techDrawer)}>
                <TechDrawerChevron invert={techDrawer} />
                <div className="tech-drawer-title">
                    Expand User Guide
                </div>
                <TechDrawerChevron invert={techDrawer} />
            </div>
            <div className={`tech-drawer-content-wrapper ${techDrawer ? `` : `hide`}`}>
                <div className="tech-drawer-main">
                    <div className="tech-drawer-detail">
                        <div className="detail-header">Something is happening here</div>
                        <div className="detail-body">As <span className="detail-persona">{ journey }</span> interacts with more elements on the page, Adobe Experience Cloud is understanding more about them to deliver intuitive, personalised experiences.</div>
                        <div className="detail-container">
                            <div className="detail-how-to">
                                <TechDrawerInfo />
                                <p onClick={() => setLightboxVisible(true)} className="how-to-label">How to use this demo</p>
                            </div>
                            <div className="detail-privacy">
                                <Eye/>
                                <p id="openPrivacyModal" onClick={() => window.adobePrivacy ? window.adobePrivacy.showConsentPopup() : ""} className="openPrivacyModal">Update your privacy preferences</p>
                            </div>
                        </div>
                      
                    </div>
                    <div className="tech-drawer-persona-swap">
                        <div className="persona-swap-label">Swap persona</div>
                        <JourneySelector onSelect={() => openTechDrawer(false)} />
                    </div>
                    <div className="tech-drawer-contact">
                        <div className="contact-wrapper">
                            <div className="contact-header">Interested in making better digital experiences?</div>
                            <div className="contact-body">Reach out to us!</div>
                            <a className="contact-link" onClick={() => fireTracking()}target="blank" href="https://www.adobe.com/au/request-consultation/experience-cloud.html">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector("#adobe-root")
    )
}

export default TechDrawer
