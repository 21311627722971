import React from 'react'

function Preferences(props) {
    return (
        <div{...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33 33" fill="none">
            <path d="M11.3948 0H3.81614C1.71194 0 0 1.71194 0 3.81614V11.3948C0 13.499 1.71194 15.2109 3.81614 15.2109H11.3948C13.499 15.2109 15.2109 13.499 15.2109 11.3948V3.81614C15.2109 1.71194 13.499 0 11.3948 0ZM12.6328 11.3948C12.6328 12.0774 12.0774 12.6328 11.3948 12.6328H3.81614C3.13352 12.6328 2.57812 12.0774 2.57812 11.3948V3.81614C2.57812 3.13352 3.13352 2.57812 3.81614 2.57812H11.3948C12.0774 2.57812 12.6328 3.13352 12.6328 3.81614V11.3948Z" fill="black"/>
            <path d="M29.1328 0H21.6562C19.5239 0 17.7891 1.73482 17.7891 3.86719V11.3438C17.7891 13.4761 19.5239 15.2109 21.6562 15.2109H29.1328C31.2652 15.2109 33 13.4761 33 11.3438V3.86719C33 1.73482 31.2652 0 29.1328 0ZM30.4219 11.3438C30.4219 12.0545 29.8436 12.6328 29.1328 12.6328H21.6562C20.9455 12.6328 20.3672 12.0545 20.3672 11.3438V3.86719C20.3672 3.1564 20.9455 2.57812 21.6562 2.57812H29.1328C29.8436 2.57812 30.4219 3.1564 30.4219 3.86719V11.3438Z" fill="black"/>
            <path d="M11.3948 17.7891H3.81614C1.71194 17.7891 0 19.501 0 21.6052V29.1839C0 31.2881 1.71194 33 3.81614 33H11.3948C13.499 33 15.2109 31.2881 15.2109 29.1839V21.6052C15.2109 19.501 13.499 17.7891 11.3948 17.7891ZM12.6328 29.1839C12.6328 29.8665 12.0774 30.4219 11.3948 30.4219H3.81614C3.13352 30.4219 2.57812 29.8665 2.57812 29.1839V21.6052C2.57812 20.9226 3.13352 20.3672 3.81614 20.3672H11.3948C12.0774 20.3672 12.6328 20.9226 12.6328 21.6052V29.1839Z" fill="black"/>
            <path d="M29.1328 17.7891H21.6562C19.5239 17.7891 17.7891 19.5239 17.7891 21.6562V29.1328C17.7891 31.2652 19.5239 33 21.6562 33H29.1328C31.2652 33 33 31.2652 33 29.1328V21.6562C33 19.5239 31.2652 17.7891 29.1328 17.7891ZM30.4219 29.1328C30.4219 29.8436 29.8436 30.4219 29.1328 30.4219H21.6562C20.9455 30.4219 20.3672 29.8436 20.3672 29.1328V21.6562C20.3672 20.9455 20.9455 20.3672 21.6562 20.3672H29.1328C29.8436 20.3672 30.4219 20.9455 30.4219 21.6562V29.1328Z" fill="black"/>
            </svg>
        </div>
    )
}

export default Preferences
