import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import HomePage from '../../pages/Homepage';
import TurnTable from '../../pages/TurnTable'
import AdobeHeader from '../adobe-header/AdobeHeader';
import TechDrawer from '../tech-drawer/TechDrawer'
import withLastVisitedTracking from '../with-last-visited/withLastVisitedTracking';
import withLastVisitedRedirect from '../with-last-visited/withLastVisitedRedirect';
import withAdobeScripts from '../with-adobe-scripts/withAdobeScripts';
import LoadingScreen from '../loading-screen/LoadingScreen'
import PageData from '../../data/pageData.json';

export default function AppRouter() {
    const routes = Object.entries(PageData)
        .flatMap((entry) => {
            const [dirname, filenames] = entry;
            return filenames.map((filename) => `/${dirname}/${filename}`);
        })
        .map((filepath, index) => {
            const pathname = filepath.replace('.jsx', '');
            const excludedPaths = ['/chloe/16', '/james/21', '/ruby/16', '/taylor/15'];
            const Page = React.lazy(() => import(`../../pages${filepath}`));
            const LazyLoadedPage = () => (
                <Suspense fallback={LoadingScreen}>
                    <Page />
                </Suspense>
            );
            const PageWithAdobeScripts = withAdobeScripts(pathname, LazyLoadedPage);
            const PageWithLastVisitedTracking = withLastVisitedTracking(pathname, excludedPaths, PageWithAdobeScripts);
            return <Route key={index} exact path={pathname} component={PageWithLastVisitedTracking} />;
        });

    return <>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <AdobeHeader />
            <Switch>
                {routes}
                <Route exact path='/' component={withAdobeScripts("", withLastVisitedRedirect(HomePage))} />
                <Route path='/turntable' component={TurnTable} />
                <Route component={() => <Redirect to='/' />} />

            </Switch>
            <TechDrawer />
        </BrowserRouter>
    </>;
}