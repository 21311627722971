import React from 'react'

function TechDrawerChevron(props) {
    return (
        <div className={`tech-drawer-chevron ${props.invert}`}>
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15" viewBox="0 0 24 15" fill="none">
            <path d="M22 2L12 12L2 2" stroke="white" strokeWidth="3"/>
            </svg> 
        </div>
    )
}

export default TechDrawerChevron

