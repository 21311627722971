import React, { useState, useEffect }from 'react'
import './Lightbox.scss'
import LightboxToolTip from './LightboxToolTip'

export default function LightboxTour(props) {

    let { tourdata, isvisible, toggletour } = props;
    const [step, setStep] = useState(0)

    sessionStorage.setItem('tourSeen', true);

    useEffect(() => {
        if (tourdata !== undefined && step === tourdata.tourSteps.length) {
            setStep(0)
            return () => {
                toggletour(false)
            }
        }
    }, [step, toggletour, tourdata])

    return (
        <div className={ isvisible ? "lightbox tour" : "lightbox hidden"}>
            <LightboxToolTip content={ tourdata.tourSteps } stepindex={ step } progresscb={ setStep } lightboxvisible={ isvisible } toggletour={ toggletour }/>
        </div>
    )
}
