import React from 'react';
import Button from '../button/Button';

function HeroBanner() {
    return (
        <div className="hero-banner">
            <div className="hero-banner-content">
                <h1>The future of citizen experience is now.</h1>
                <p>Explore the possibilities with Adobe’s live Education demo.</p>
                <Button onClick={() => window.scrollTo(0, document.querySelector(".persona-selector").getBoundingClientRect().top - 208)} className="hero-banner-btn" primary animation>Start Demo</Button>
            </div>
        </div>
    )
}

export default HeroBanner
