import React from 'react';
import './ArticleListItem.scss';

export default ({ imageURL, title, description, ctaCopy }) => (
    <div className="article-list-item">
        <img src={imageURL} alt={title} className="article-list-item__image"/>
        <div className="article-list-item__copy">
            <h4 className="article-list-item__title">{title}</h4>
            <p className="article-list-item__description">{description}</p>
            <span className="article-list-item__link">{ctaCopy}</span>
        </div>
    </div>
)