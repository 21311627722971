import React from 'react';

function Grid(props) {
    return (
        <div className="grid">
            {props.children}
        </div>
    )
}

export default Grid;