import React from 'react';
import './Button.scss';

const fireTracking = () => {
    const intervalId = setInterval(() => {
        if (window.digitalData) {
            clearInterval(intervalId);

            window.digitalData._set( 
                'digitalData.primaryEvent.eventInfo.eventName', `${window.location.pathname} Button Progression Click` 
            ); 
            window._satellite.track('event', { digitalData: window.digitalData._snapshot() }); 
            window.digitalData._delete('digitalData.primaryEvent.eventInfo.eventName'); 
        }
    }, 100)
}

export default ({ children, primary, secondary, inactive, animation, className = '', ...props }) => (
    <button onClick={primary && animation ? () => fireTracking() : ""} className={`button ${animation ? 'pulse-animation': '' } ${primary ? 'primary': ''} ${secondary ? 'secondary' : ''} ${inactive ? 'inactive': ''} ${className}`} {...props}>
        {children}
    </button>
)