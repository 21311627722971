import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PreLogInNav from '../components/navigation/PreLogInNav';
import HeroBanner from '../components/hero-banner/HeroBanner';
import PersonaSelector from '../components/persona-selector/PersonaSelector';
import List from '../components/list/List';
import ArticleListItem from '../components/article-list-item/ArticleListItem';
import Grid from '../components/grid/Grid'
import GridTile from '../components/grid/GridTile';
import Button from '../components/button/Button'
import Lightbox from '../components/lightbox/Lightbox'
import LightboxTour from '../components/lightbox/LightboxTour'
import Footer from '../components/footer/Footer'
import ProgressAnim from '../components/progress-animation/ProgressAnimation'
import { Helmet } from "react-helmet";
import './faas.scss'
import './Homepage.scss'
import tourdata from '../data/lightbox-tour.json'
import ObjectObserver from '../ObjectObserver';

import CovidImg from './james/assets/News-Covid.svg';
import SchoolsImg from './james/assets/News-Schools.svg';
import SurfImg from './james/assets/News-Surf.svg';

import MathsHubImg from './assets/icon-mathshub.png';
import GrammarImg from './assets/icon-grammar.png';
import MathleticsImg from './assets/icon-mathletics.png';
import ScienceImg from './assets/icon-science.png';

import EmptyStar from './assets/emptystar.svg'
import HalfStar from './assets/halfstar.svg'
import Star from './assets/star.svg'

function HomePage() {
    const [deviceSize, setDeviceSize] = useState(window.innerWidth > 768 ? 'desktop' : 'mobile');

    const [faasLightbox, showFaasLightbox] = useState(false);
    const [tourLightbox, showTourLightbox] = useState(true);

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        sessionStorage.setItem('tourSeen', true);
    }

    (tourLightbox && !sessionStorage.getItem('tourSeen')) || faasLightbox ?
        document.body.style.overflow = "hidden" :
        document.body.style.overflow = "scroll" ;

    const history = useHistory();

    useEffect(() => {
        function handleResize() {
            setDeviceSize(window.innerWidth > 768 ? 'desktop' : 'mobile')
        }
        window.addEventListener('resize', handleResize)

        let leadParams = [
            "7011O000002dwuqQAA",
            "7011O000002dwuvQAA",
            "7011O000002dwvFQAQ",
            "7011O000002dwvKQAQ",
            "7011O000002dwvPQAQ",
            "7011O000002dwvUQAQ"
        ]

        leadParams.forEach(string => {
            if (window.location.search.indexOf(string) > -1) {
                localStorage.setItem('faasSubmitted', true)
            }
        });

        const intervalId = setInterval(() => {
            if (window.digitalData && window.digitalData.form) {
                clearInterval(intervalId);

                const objectObserver = new ObjectObserver(window.digitalData, "form");
                objectObserver.observe(() => {
                    const { form } = window.digitalData;
                    if (form && form.response && form.response.submissionID) {
                        window.localStorage.setItem('faasSubmitted', true);
                        document.body.style.overflow = "scroll" ;
                        let persona = window.sessionStorage.getItem('desiredPersona');
                        history.push(persona ? `/${persona}/02` : '/')
                    }
                })
            }
        }, 100)

    }, [ deviceSize, history ])

    return (
        <div className="homepage">
            <div className="pre-log-in-layout">
                <PreLogInNav />
                <div className="hero-persona-wrapper">
                    <HeroBanner/>
                    <PersonaSelector faasToggle={showFaasLightbox}/>
                    <Grid>
                        <GridTile  screenWidth={deviceSize} sm-columns="span 6" sm-rows="span 6" lg-columns="span 7" lg-rows="span 6">
                            <h2>Local News & Events</h2>
                            <div className="news-sub">Showing based on current postcode: 
                                <strong>2230</strong>
                                <span>Change location</span>
                                <ProgressAnim>
                                <h2>Adobe Experience Cloud</h2>
                                <p>
                                    Analytics segments users by their geo locations, by identifying browser IP address.<br/><br/>
                                    Through an integration with Analytics, Target is used to surface regional relevant content for the user’s location.
                                </p>
                                </ProgressAnim>
                            </div>
                            <List>

                                <ArticleListItem 
                                    imageURL={SchoolsImg}
                                    title="Schools in St George and Sutherland Shire (COVID-19)"
                                    description="Schools in St George and Sutherland Shire are telling parents to keep children home and to continue classes online, admist an outbreak in local playgrounds ..."
                                    ctaCopy="Learn more"
                                />
                                <ArticleListItem 
                                    imageURL={CovidImg}
                                    title="Monday 10 September: Parent-teachers meeting"
                                    description="Quarterly meeting to discuss your child’s progress - their strengths, areas for improvement and what you can do to support them."
                                    ctaCopy="Learn more"
                                />
                                <ArticleListItem 
                                    imageURL={SurfImg}
                                    title="Junior surfing titles go to the Gold Coast"
                                    description="The Havaianas NSW Grommet State Titles were completed in the under 12 divisions on the final day of the surfing titles. Competitors will now head to the Gold Coast for ..."
                                    ctaCopy="Learn more"
                                />
                            </List>
                            <Button inactive>View more</Button>
                        </GridTile>
                        <GridTile screenWidth={deviceSize} sm-columns="span 6" sm-rows="span 6" lg-columns="span 5" lg-rows="span 6">
                            <List title="Top Apps" className="">
                                <div className="app-listitem">
                                    <div>
                                        <img className="app-logo" src={MathsHubImg} alt=""/>
                                    </div>
                                    <div>
                                        <h4>1. Maths Hub</h4>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={HalfStar} alt=""/>
                                    </div>
                                </div>
                                <div className="app-listitem">
                                    <div>
                                        <img className="app-logo" src={MathleticsImg} alt=""/>
                                    </div>
                                    <div>
                                        <h4>2. Mathletics</h4>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={EmptyStar} alt=""/>
                                    </div>
                                </div>
                                <div className="app-listitem">
                                    <div>
                                        <img className="app-logo" src={GrammarImg} alt=""/>
                                    </div>
                                    <div>
                                        <h4>3. Grammar Rules</h4>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={EmptyStar} alt=""/>
                                        <img className="rating-star" src={EmptyStar} alt=""/>
                                    </div>
                                </div>
                                <div className="app-listitem">
                                    <div>
                                        <img className="app-logo" src={ScienceImg} alt=""/>
                                    </div>
                                    <div>
                                        <h4>4. The World of Science</h4>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={Star} alt=""/>
                                        <img className="rating-star" src={EmptyStar} alt=""/>
                                        <img className="rating-star" src={EmptyStar} alt=""/>
                                    </div>
                                </div>
                            </List>
                            <Button inactive>View more</Button>
                        </GridTile>
                    </Grid>
                    
                    <Lightbox faas onOutsideClick={() => showFaasLightbox(false)} isvisible={faasLightbox}>
                        <div id="form"></div>
                    </Lightbox>

                    {
                        sessionStorage.getItem("tourSeen") ? "" : <LightboxTour isvisible={tourLightbox} tourdata={tourdata} toggletour={showTourLightbox}/>
                    }
                    
                    <Helmet>
                        <script type="text/javascript" language="javascript" src="./homepage-faas.js"></script>
                    </Helmet>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage;
