import React from 'react'

function Eye(props) {
    return (
        <div {...props}>
            <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.08889 7.58783C0.945485 7.90484 0.977159 8.27359 1.17252 8.5615C2.15791 10.0137 5.58828 14 11.5 14C17.4117 14 20.8421 10.0137 21.8275 8.5615C22.0228 8.27359 22.0545 7.90484 21.9111 7.58783C20.9113 5.3777 17.4612 1 11.5 1C5.5388 1 2.08871 5.3777 1.08889 7.58783Z" fill="#333333" stroke="#333333" strokeWidth="2" strokeLinejoin="round"/>
            <circle cx="11.5" cy="7.5" r="4.5" fill="white"/>
            </svg>
        </div>
    )
}

export default Eye
