import React from 'react'

function TechDrawerInfo() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.0081 8.30719C9.43005 8.30719 9.01904 8.55126 9.01904 8.91091V13.8047C9.01904 14.113 9.4301 14.4213 10.0081 14.4213C10.5604 14.4213 11.01 14.113 11.01 13.8047V8.91091C11.01 8.55126 10.5604 8.30719 10.0081 8.30719Z" fill="#505051"/>
            <path d="M10.0079 5.2887C9.41701 5.2887 8.95459 5.71257 8.95459 6.20067C8.95459 6.68876 9.41701 7.1255 10.0079 7.1255C10.5859 7.1255 11.0483 6.68876 11.0483 6.20067C11.0483 5.71262 10.5859 5.2887 10.0079 5.2887Z" fill="#505051"/>
            <circle cx="10" cy="10" r="9" stroke="#505051" strokeWidth="2"/>
            </svg>
        </div>
    )
}

export default TechDrawerInfo
