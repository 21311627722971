import React from 'react'

function Hamburger(props) {
    
    let { color } = props;
    return (
        <div {...props}>
            <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="1.5" x2="20.5" y2="1.5" stroke={ color } strokeWidth="3" strokeLinecap="round"/>
                <line x1="1.5" y1="9.5" x2="20.5" y2="9.5" stroke={ color } strokeWidth="3" strokeLinecap="round"/>
                <line x1="1.5" y1="17.5" x2="20.5" y2="17.5" stroke={ color } strokeWidth="3" strokeLinecap="round"/>
            </svg>
        </div>
    )
}

export default Hamburger;
