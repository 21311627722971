import React from 'react';
import './Footer.scss';

export default () => (
    <div className="footer">
        <div className="footer__copy">We acknowledge the Traditional Owners and ongoing custodians of the land pay our respect to Country.</div>
        <div className="footer__links">
            <div className="footer__copy">Privacy Policy</div>
            <div className="footer__copy">Accessibility</div>
            <div className="footer__copy">Copyright</div>
            <div className="footer__copy">Disclaimer</div>
        </div>
    </div>
)