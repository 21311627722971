import React from 'react'

function TeacherPersona(props) {
    return (
        <div {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="108" viewBox="0 0 70 89" fill="none">
            <path d="M37.8976 0.498034C49.0858 2.09132 63.5486 17.1967 63.2581 21.3516C63.2485 21.9157 63.1186 22.4713 62.8773 22.9813C62.6359 23.4914 62.2886 23.9441 61.8585 24.3093C61.5116 24.6275 61.2412 25.02 61.0674 25.4575C60.8936 25.895 60.821 26.3661 60.855 26.8356C61.2423 31.7211 61.4184 37.1259 61.0927 39.0801C37.8448 42.2007 23.8151 42.2007 8.02137 39.0801C6.62174 33.6929 8.69917 29.2652 6.70097 28.7722C4.04256 27.0821 9.03367 12.8834 19.7817 9.64401C19.7817 9.64401 21.6039 -1.39454 37.8976 0.498034Z" fill="#FFC440"/>
            <path d="M55.996 39.1334C56.7816 38.7246 57.6525 38.5068 58.5381 38.4976C59.4236 38.4884 60.2989 38.6882 61.0928 39.0806C63.6808 40.3129 64.4818 43.4555 63.4079 46.8797C62.3516 50.2776 58.1439 53.3849 54.9133 52.1877C50.6748 51.774 50.4433 41.3481 55.996 39.1334Z" fill="#FF7364"/>
            <path d="M17.3056 45.0252C17.4377 47.2523 17.8558 50.5639 14.2009 52.188C10.9703 53.3851 6.77146 50.2778 5.70633 46.8799C4.64121 43.4557 5.43345 40.3131 8.02143 39.0808C8.81686 38.6882 9.69354 38.4884 10.5805 38.4976C11.4675 38.5068 12.3398 38.7247 13.127 39.1336C15.8162 40.8387 17.156 42.5077 17.3056 45.0252Z" fill="#FF7364"/>
            <path d="M13.2415 45.9818C13.1012 45.9821 12.9629 45.9488 12.8382 45.8848C12.7134 45.8208 12.6057 45.7279 12.524 45.6139C12.1519 45.1326 11.684 44.7337 11.15 44.4423C10.6159 44.151 10.0272 43.9735 9.42111 43.9211C9.18821 43.9115 8.96864 43.8099 8.81055 43.6386C8.65246 43.4673 8.56876 43.2403 8.57781 43.0074C8.57916 42.8916 8.60401 42.7772 8.65084 42.6712C8.69767 42.5652 8.76552 42.4698 8.85028 42.3908C8.93505 42.3118 9.03495 42.2509 9.14396 42.2116C9.25297 42.1723 9.36882 42.1556 9.48449 42.1624C10.3603 42.2224 11.213 42.4702 11.9846 42.889C12.7562 43.3077 13.4286 43.8876 13.9562 44.5893C14.0504 44.7208 14.1064 44.8757 14.1183 45.0369C14.1302 45.1982 14.0974 45.3597 14.0236 45.5035C13.9497 45.6474 13.8377 45.7682 13.6997 45.8525C13.5617 45.9369 13.4032 45.9816 13.2415 45.9818Z" fill="#ED4850"/>
            <path d="M55.8754 45.9745C55.7084 45.9739 55.5451 45.9259 55.4044 45.836C55.2637 45.7462 55.1514 45.6181 55.0807 45.4669C55.01 45.3156 54.9838 45.1474 55.0051 44.9818C55.0264 44.8162 55.0944 44.6601 55.2011 44.5317C55.7319 43.8466 56.4014 43.2814 57.1658 42.8731C57.9302 42.4647 58.7722 42.2224 59.6367 42.162C59.7532 42.1543 59.87 42.1706 59.98 42.2098C60.0899 42.249 60.1907 42.3104 60.276 42.3901C60.3613 42.4698 60.4293 42.5661 60.4759 42.6731C60.5225 42.7802 60.5467 42.8956 60.5469 43.0124C60.5546 43.2448 60.4699 43.4708 60.3114 43.6411C60.1528 43.8113 59.9334 43.9118 59.701 43.9208C59.0949 43.9739 58.5063 44.1515 57.9719 44.4425C57.4375 44.7334 56.9689 45.1315 56.5954 45.6118C56.5635 45.6556 56.5279 45.6965 56.4889 45.7341C56.3228 45.89 56.1031 45.976 55.8754 45.9745Z" fill="#ED4850"/>
            <path d="M44.0245 75.4969C44.0011 78.3121 43.0481 81.0407 41.3137 83.2583C39.5793 85.4759 37.1606 87.0581 34.434 87.759C27.3918 86.2537 25.266 78.4546 25.266 76.0338C25.266 76.0074 26.1375 74.3437 26.1375 74.3173C26.6321 73.4476 26.9885 72.5062 27.1938 71.5269C27.4288 70.4337 27.541 69.3176 27.5283 68.1995L27.5371 68.1907C27.5459 68.1907 27.5459 68.1995 27.5547 68.1995C29.8522 69.335 39.2622 69.335 41.5597 68.1995C41.5489 69.2754 41.6522 70.3495 41.8678 71.4036C42.0698 72.4268 42.4354 73.4106 42.9506 74.3173C42.9506 74.3349 44.0245 75.4793 44.0245 75.4969Z" fill="#FF7364"/>
            <path d="M41.8676 71.4037C39.6791 72.8736 37.0918 73.6357 34.4558 73.5867C31.8854 73.6278 29.3597 72.9114 27.1936 71.5269C27.4286 70.4337 27.5408 69.3176 27.5281 68.1995L27.5369 68.1907C27.5457 68.1907 27.5457 68.1995 27.5545 68.1995C29.852 69.335 39.2621 69.335 41.5596 68.1995C41.5487 69.2754 41.652 70.3495 41.8676 71.4037Z" fill="#ED4850"/>
            <path d="M55.996 39.1331C56.0048 40.4007 56.0312 42.5749 55.8816 45.0925L55.8728 45.1013C55.7517 47.486 55.4307 49.8564 54.9133 52.1875C53.1527 59.7401 47.3958 65.5147 41.5596 68.1995C39.3775 69.2596 36.9831 69.8104 34.5571 69.8104C32.1311 69.8104 29.7367 69.2596 27.5545 68.1995C27.5457 68.1995 27.5457 68.1907 27.5369 68.1907C21.7184 65.5059 15.9702 59.7313 14.2009 52.1875C13.6835 49.8564 13.3625 47.486 13.2414 45.1013C13.0917 42.5837 13.1182 40.4007 13.127 39.1331C13.3382 30.7001 16.0494 18.0243 25.5299 15.6388C30.2306 11.0526 39.2357 12.7515 44.4821 16.6775C47.9493 19.3297 50.7817 22.7214 52.7734 26.6058C54.7651 30.4902 55.866 34.7697 55.996 39.1331Z" fill="#FF977B"/>
            <path d="M68.1172 94.3434C65.0244 89.8069 61.0301 85.9564 56.3833 83.0319C56.3765 83.0313 56.3702 83.0282 56.3657 83.0231C55.7905 82.1695 55.152 81.3603 54.4555 80.6024C53.0999 79.1588 50.3798 76.8173 47.8623 75.981C47.8623 75.981 43.5666 73.7099 42.9328 74.3349C41.6036 75.6553 39.9399 77.7327 38.461 79.3348C36.8035 81.0664 35.4761 83.0859 34.5438 85.2942C33.6116 83.0859 32.2841 81.0664 30.6266 79.3348C29.1478 77.7327 27.4841 75.6553 26.1549 74.3349C25.5211 73.7099 21.2254 75.981 21.2254 75.981C18.7078 76.8173 15.9878 79.1588 14.6322 80.6024C13.9324 81.3652 13.2882 82.1771 12.7044 83.0319C8.05761 85.9564 4.06324 89.8069 0.970449 94.3434C-1.34465 98.0229 0.794395 100.021 4.1042 101.852C7.45405 103.524 11.0039 104.76 14.6674 105.532C17.8553 106.272 21.0863 106.813 24.3415 107.151C31.1254 107.844 37.9622 107.844 44.7461 107.151C48.0014 106.813 51.2323 106.272 54.4203 105.532C58.0838 104.76 61.6336 103.524 64.9835 101.852C68.2933 100.021 70.4323 98.0229 68.1172 94.3434Z" fill="#B76448"/>
            <path d="M38.4611 79.335C38.4611 79.335 44.351 82.1289 47.5103 83.1817C47.0114 91.2147 46.0888 99.2157 44.7462 107.151C37.9623 107.844 31.1256 107.844 24.3417 107.151C22.9991 99.2157 22.0765 91.2147 21.5776 83.1817C23.2501 82.7469 30.6268 79.335 30.6268 79.335C32.2843 81.0665 33.6117 83.0861 34.544 85.2944C35.4762 83.0861 36.8037 81.0665 38.4611 79.335Z" fill="#EFE0C5"/>
            <path d="M10.5566 88.7188C11.1375 90.0656 15.9966 89.9775 15.9966 89.9775C15.9966 89.9775 11.2784 93.1025 11.428 95.0215C11.7977 99.7309 13.9544 103.851 14.6674 105.532C11.0039 104.761 7.45405 103.524 4.1042 101.852C0.794395 100.021 -1.34465 98.0232 0.970449 94.3437C4.06324 89.8072 8.05761 85.9566 12.7044 83.0322C11.2256 85.2241 10.1076 87.6712 10.5566 88.7188Z" fill="#9E5643"/>
            <path d="M68.1173 94.3437C70.4324 98.0232 68.2934 100.021 64.9836 101.852C61.6337 103.524 58.0839 104.761 54.4204 105.532C55.1334 103.851 57.2901 99.7309 57.6598 95.0215C57.8094 93.1025 53.0912 89.9775 53.0912 89.9775C53.0912 89.9775 57.9503 90.0656 58.5312 88.7188C58.9802 87.6712 57.8622 85.2241 56.3834 83.0322C61.0302 85.9566 65.0246 89.8072 68.1173 94.3437Z" fill="#9E5643"/>
            <path d="M30.6267 79.3353C30.6267 79.3353 27.9418 86.1046 24.8433 87.0113C23.4173 87.4338 22.2905 85.5148 21.5775 83.1821C21.4191 80.7878 21.3046 78.3758 21.2254 75.9815C22.0133 75.1327 22.9351 74.419 23.9542 73.8689C24.3197 73.6863 24.7364 73.633 25.1361 73.7177C25.5358 73.8025 25.8949 74.0202 26.1549 74.3354C27.4841 75.6558 29.1478 77.7332 30.6267 79.3353Z" fill="white"/>
            <path d="M47.8623 75.9815C47.7831 78.3758 47.6687 80.7878 47.5102 83.1821C46.7972 85.5148 45.6704 87.4338 44.2444 87.0113C41.1459 86.1046 38.4611 79.3353 38.4611 79.3353C39.9399 77.7332 41.6036 75.6558 42.9328 74.3354C43.1928 74.0202 43.552 73.8025 43.9517 73.7177C44.3514 73.633 44.768 73.6863 45.1335 73.8689C46.1526 74.419 47.0745 75.1327 47.8623 75.9815Z" fill="white"/>
            <path d="M47.3958 39.7359C47.3958 38.6173 46.4892 37.7104 45.3708 37.7104C44.2523 37.7104 43.3457 38.6173 43.3457 39.7359V40.9754C43.3457 42.094 44.2523 43.0009 45.3708 43.0009C46.4892 43.0009 47.3958 42.094 47.3958 40.9754V39.7359Z" fill="#5C4666"/>
            <path d="M21.7183 40.9755C21.7183 42.0941 22.625 43.001 23.7434 43.001C24.8618 43.001 25.7684 42.0941 25.7684 40.9755V39.7361C25.7684 38.6174 24.8618 37.7106 23.7434 37.7106C22.625 37.7106 21.7183 38.6174 21.7183 39.7361V40.9755Z" fill="#5C4666"/>
            <path d="M49.3325 13.7635C49.1872 13.7638 49.0442 13.728 48.9161 13.6595C48.788 13.591 48.6788 13.4919 48.5983 13.3709C47.4461 11.6377 42.1618 8.05941 36.1672 7.39921C34.1486 7.1007 32.0883 7.26942 30.1451 7.89238C28.2019 8.51534 26.4276 9.57591 24.9588 10.9925C24.8761 11.0757 24.7775 11.1416 24.6689 11.1862C24.5604 11.2308 24.444 11.2532 24.3266 11.2522C24.2092 11.2511 24.0933 11.2266 23.9855 11.1801C23.8777 11.1336 23.7804 11.066 23.6991 10.9813C23.6179 10.8966 23.5544 10.7965 23.5124 10.6868C23.4704 10.5772 23.4508 10.4603 23.4546 10.343C23.4585 10.2257 23.4858 10.1104 23.5349 10.0038C23.584 9.89715 23.6539 9.80143 23.7405 9.72223C25.4003 8.13477 27.3979 6.94356 29.5835 6.23788C31.7691 5.5322 34.0861 5.33036 36.3609 5.64748C42.5227 6.32616 48.4971 10.0347 50.0657 12.3947C50.1542 12.5274 50.205 12.6815 50.2127 12.8408C50.2204 13 50.1847 13.1584 50.1094 13.2989C50.0342 13.4395 49.9221 13.5569 49.7853 13.6388C49.6484 13.7206 49.4919 13.7637 49.3325 13.7635Z" fill="#F29D20"/>
            <path d="M34.5615 48.9394C34.5316 48.9394 31.6038 48.8699 31.6038 47.3285C31.6035 47.1317 31.6693 46.9404 31.7904 46.7853C31.9116 46.6302 32.0813 46.5202 32.2724 46.4729C32.4635 46.4255 32.6649 46.4436 32.8445 46.5242C33.0241 46.6048 33.1715 46.7432 33.2631 46.9174C33.6784 47.0765 34.1171 47.1657 34.5615 47.1815C35.0031 47.1638 35.4389 47.0746 35.852 46.9174C35.9439 46.7437 36.0912 46.6057 36.2706 46.5254C36.45 46.4452 36.6512 46.4273 36.8419 46.4746C37.0327 46.5218 37.2021 46.6316 37.3233 46.7864C37.4444 46.9411 37.5103 47.132 37.5104 47.3285C37.5104 48.869 34.5914 48.9394 34.5615 48.9394Z" fill="#ED4850"/>
            <path d="M34.6521 55.6467C34.4312 55.6467 34.2199 55.6467 34.0245 55.6388C29.9471 55.5358 29.529 54.5358 29.3917 54.2075C29.3043 54.0026 29.2978 53.7721 29.3737 53.5626C29.4495 53.3531 29.602 53.1801 29.8003 53.0786C29.9987 52.9771 30.2281 52.9546 30.4424 53.0156C30.6567 53.0766 30.8399 53.2166 30.955 53.4073C31.9233 54.0561 37.2498 54.0517 38.1653 53.3985C38.285 53.2084 38.4729 53.0713 38.6905 53.0152C38.9081 52.9592 39.1389 52.9884 39.3356 53.097C39.5323 53.2056 39.68 53.3853 39.7485 53.5993C39.817 53.8133 39.8011 54.0454 39.704 54.248C39.1397 55.4276 36.5227 55.6467 34.6521 55.6467Z" fill="#ED4850"/>
            <path d="M42.4661 39.3177C42.2803 39.3179 42.0992 39.2592 41.9487 39.1501C41.7983 39.0411 41.6862 38.8872 41.6286 38.7105C41.5709 38.5339 41.5707 38.3435 41.6278 38.1667C41.685 37.9899 41.7966 37.8357 41.9468 37.7262C43.0967 36.9324 44.4391 36.4628 45.833 36.3666C47.227 36.2704 48.6211 36.5512 49.8692 37.1795C50.0415 37.3264 50.1506 37.534 50.1738 37.7592C50.1971 37.9844 50.1326 38.2099 49.9939 38.3888C49.8552 38.5678 49.6529 38.6864 49.429 38.72C49.2051 38.7537 48.9769 38.6998 48.7917 38.5695C47.8501 38.1822 46.8276 38.0325 45.8145 38.1335C44.8014 38.2345 43.8286 38.5832 42.982 39.1487C42.8323 39.2584 42.6517 39.3176 42.4661 39.3177Z" fill="#4B3956"/>
            <path d="M40.4151 36.1841C40.2088 36.1845 40.0089 36.1125 39.8503 35.9805C39.6918 35.8486 39.5845 35.6651 39.5474 35.4622C39.5103 35.2592 39.5457 35.0497 39.6473 34.8702C39.7489 34.6906 39.9104 34.5525 40.1035 34.4799C47.2724 31.7722 51.8709 34.17 52.0637 34.273C52.1668 34.327 52.2582 34.4009 52.3325 34.4904C52.4069 34.5799 52.4628 34.6833 52.497 34.7945C52.5311 34.9058 52.5429 35.0227 52.5316 35.1385C52.5202 35.2543 52.4861 35.3668 52.431 35.4693C52.376 35.5718 52.3011 35.6624 52.2109 35.7359C52.1206 35.8093 52.0166 35.8641 51.905 35.8971C51.7934 35.9301 51.6764 35.9406 51.5607 35.9281C51.445 35.9155 51.3329 35.8802 51.231 35.8241C51.194 35.8038 47.0981 33.7202 40.7259 36.1278C40.6266 36.1653 40.5213 36.1844 40.4151 36.1841Z" fill="#FFC440"/>
            <path d="M26.6469 39.3179C26.4605 39.3176 26.2789 39.2582 26.1285 39.148C25.2804 38.5819 24.3058 38.2336 23.2911 38.1341C22.2763 38.0346 21.2526 38.187 20.3108 38.5776C20.1189 38.7053 19.8856 38.755 19.6584 38.7166C19.4312 38.6781 19.2272 38.5544 19.0881 38.3708C18.9524 38.1918 18.8924 37.9667 18.921 37.7439C18.9496 37.5211 19.0647 37.3185 19.2413 37.1798C20.4892 36.551 21.8834 36.2699 23.2775 36.3661C24.6715 36.4623 26.0139 36.9322 27.1636 37.7264C27.3138 37.8359 27.4254 37.9901 27.4826 38.1669C27.5398 38.3437 27.5395 38.5341 27.4818 38.7107C27.4242 38.8874 27.3121 39.0413 27.1617 39.1504C27.0112 39.2594 26.8301 39.3181 26.6443 39.3179H26.6469Z" fill="#4B3956"/>
            <path d="M28.6988 36.1842C28.5923 36.1844 28.4868 36.1653 28.3872 36.1279C22.0149 33.7203 17.9208 35.8057 17.8794 35.826C17.6735 35.936 17.4323 35.9598 17.2088 35.892C16.9854 35.8243 16.798 35.6705 16.688 35.4646C16.5779 35.2587 16.5541 35.0175 16.6219 34.794C16.6896 34.5706 16.8434 34.3832 17.0493 34.2732C17.243 34.171 21.8424 31.7732 29.0095 34.48C29.2025 34.5526 29.3639 34.6907 29.4656 34.8701C29.5672 35.0495 29.6026 35.259 29.5656 35.4619C29.5286 35.6647 29.4216 35.8482 29.2632 35.9802C29.1047 36.1122 28.905 36.1844 28.6988 36.1842Z" fill="#FFC440"/>
            </svg>
        </div>
    )
}

export default TeacherPersona
