import React from 'react';
import { useHistory } from 'react-router-dom';
import StudentPersona from '../icons/student-persona'
import ParentPersona from '../icons/parent-persona'
import TeacherPersona from '../icons/teacher-persona'
import StaffPersona from '../icons/staff-persona'
import ColorMap from '../../data/colorMap.json';

function PersonaSelector(props) {
    const history = useHistory();
    const colorKeys = Object.values( ColorMap )

    const storeDesiredPersona = (persona) => {
        sessionStorage.setItem('desiredPersona', persona);

        if (!localStorage.getItem('faasSubmitted')) {
            props.faasToggle(true)
        } else {
            history.push(`/${persona}/02`)
        }
    }

    return (
        <div className="persona-selector">
            <p className="persona-selector-title">Select your login portal to begin.</p>
            <div className="persona-selector-content">
                <div className="persona-selector-item pulse-animation" onClick={() => storeDesiredPersona("chloe")}>
                    <div className='icon'>
                        <StudentPersona />
                    </div>
                    <div className='title' style={{ backgroundColor: colorKeys[0] }}>
                        <div className="title-content">Students</div>
                    </div>
                </div>
                <div className="persona-selector-item pulse-animation" onClick={() => storeDesiredPersona("james")}>
                    <div className='icon'>
                        <ParentPersona />
                    </div>
                    <div className='title' style={{ backgroundColor: colorKeys[1] }}>
                        <div className="title-content">Parents</div>
                    </div>
                </div>
                <div className="persona-selector-item pulse-animation" onClick={() => storeDesiredPersona("ruby")}>
                    <div className='icon'>
                        <TeacherPersona />
                    </div>
                    <div className='title' style={{ backgroundColor: colorKeys[2] }}>
                        <div className="title-content">Teachers</div>
                    </div>
                </div>
                <div className="persona-selector-item pulse-animation" onClick={() => storeDesiredPersona("taylor")}>
                    <div className='icon'>
                        <StaffPersona />
                    </div>
                    <div className='title' style={{ backgroundColor: colorKeys[3] }}>
                        <div className="title-content">Staff</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonaSelector;
