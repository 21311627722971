import React, { useEffect } from 'react';

const initialiseAdobeScripts = (pageName, callback) => {

    let existingScript = document.getElementById('adobe-tech');

    if (existingScript) {
        const intervalId = setInterval(() => {
            if (window.digitalData) {
                clearInterval(intervalId);

                window.digitalData._set('digitalData.page.pageInfo.pageName', pageName);
                window._satellite.track('state', { digitalData: window.digitalData._snapshot() });
                window.digitalData._delete('digitalData.page.pageInfo.pageName')
            }
        }, 100)
    } else {
        const script = document.createElement('script');
        script.src = 'https://www.adobe.com/marketingtech/main.min.js';
        script.id = 'adobe-tech';
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }

    let existingPixelScript = document.getElementById('adobe-pixel');
    if (existingPixelScript) {
        existingPixelScript.remove();
    }

    const pixelScript = document.createElement('script');
    pixelScript.src = '/adobePixels.js';
    pixelScript.id = 'adobe-pixel';
    document.body.appendChild(pixelScript);
    pixelScript.onload = () => {
        if (callback) callback();
    };
};

export default (pageName = "", WrappedComponent) => props => {
    useEffect(() => { initialiseAdobeScripts(`governmentdemo.adobe.com${pageName.replaceAll("/", ":")}`); })
    return <WrappedComponent {...props} />
}