import React from 'react'

function ParentPersona(props) {
    return (
        <div {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="108" viewBox="0 0 67 89" fill="none">
            <path d="M33.4005 0.279785C27.7255 0.279785 20.8277 2.64417 18.4342 6.76732C18.3226 6.96259 18.1405 7.10621 17.9265 7.16784C7.69347 10.0921 3.32116 24.0671 5.54203 41.5641C6.00547 45.2128 6.06275 48.0781 7.13977 51.9099L7.10419 51.8896C7.25693 52.3799 7.41228 52.8535 7.56936 53.3165C7.90696 54.342 8.32267 55.445 8.83471 56.6474C8.89893 56.7997 8.96315 56.9529 9.02824 57.1017C9.05341 57.1598 9.07424 57.2126 9.10028 57.2707C13.542 67.3902 18.7865 69.2924 18.4038 73.2536C18.048 76.9947 25.798 74.0194 25.798 74.0194C32.4806 72.1867 34.5938 71.8012 41.003 74.0194C41.003 74.0194 48.753 76.9947 48.3972 73.2536C47.9546 68.6762 55.0364 66.8541 59.6968 51.8896C59.6361 51.9283 59.571 51.96 59.5093 51.9979C60.5588 48.8564 61.3996 45.6471 62.0262 42.3916C66.8046 16.0717 50.3152 0.279785 33.4005 0.279785Z" fill="#9B4E4E"/>
            <path d="M11.2006 40.9045C16.1787 45.6693 15.4471 49.7405 13.0492 52.8232C12.052 53.1243 11.0014 53.1967 9.97321 53.0354C8.94505 52.874 7.96512 52.4828 7.10431 51.8901C5.77701 51.0876 4.76493 49.8433 4.24036 48.3691C3.3291 45.5258 3.8151 43.0435 5.54215 41.5647C5.87745 41.277 6.2479 41.0344 6.64434 40.8428C7.78992 40.2795 10.1158 40.0946 11.2006 40.9045Z" fill="#FF7364"/>
            <path d="M62.0227 42.3915C61.5365 41.7267 60.8954 41.1944 60.1568 40.8423C59.4089 40.5073 58.6092 40.3072 57.7936 40.2507C57.7936 40.2507 57.9385 40.8423 57.7519 41.0518C57.443 41.3986 56.3729 40.7877 55.6526 40.5958C50.9027 45.1001 51.3818 49.9046 53.7259 52.8139L53.7519 52.8227C54.7491 53.1237 55.7998 53.1962 56.8279 53.0348C57.8561 52.8734 58.836 52.4823 59.6968 51.8896C61.0241 51.087 62.0362 49.8428 62.5608 48.3685C63.3245 46.0094 63.1162 43.888 62.0227 42.3915Z" fill="#FF7364"/>
            <path d="M11.7656 47.9812C11.6309 47.9812 11.498 47.9494 11.3775 47.8883C11.257 47.8272 11.1521 47.7384 11.0713 47.6291C10.6864 47.1707 10.1962 46.8153 9.64394 46.5944C9.09166 46.3735 8.49428 46.2938 7.90447 46.3624C7.67754 46.3911 7.4486 46.3278 7.26743 46.1862C7.08626 46.0447 6.9675 45.8363 6.93697 45.6064C6.90644 45.3766 6.9666 45.1437 7.10438 44.9586C7.24216 44.7735 7.44641 44.651 7.67275 44.6177C8.56848 44.5097 9.47662 44.6321 10.3134 44.9738C11.1502 45.3155 11.8887 45.8654 12.4608 46.5728C12.5575 46.7036 12.6164 46.8591 12.6308 47.0219C12.6453 47.1847 12.6148 47.3484 12.5427 47.4946C12.4706 47.6408 12.3598 47.7638 12.2227 47.8497C12.0856 47.9357 11.9276 47.9812 11.7665 47.9812H11.7656Z" fill="#ED4850"/>
            <path d="M55.0351 47.9814C54.8739 47.9814 54.7159 47.9359 54.5788 47.85C54.4417 47.764 54.3309 47.6411 54.2589 47.4948C54.1868 47.3486 54.1563 47.1849 54.1707 47.0221C54.1852 46.8593 54.2441 46.7038 54.3408 46.573C54.9134 45.8662 55.652 45.3168 56.4886 44.9752C57.3253 44.6336 58.2332 44.5107 59.1288 44.618C59.3569 44.6492 59.5634 44.7711 59.7029 44.9569C59.8424 45.1426 59.9034 45.3769 59.8726 45.6082C59.8417 45.8396 59.7216 46.0491 59.5385 46.1905C59.3554 46.332 59.1243 46.3939 58.8962 46.3626C58.3065 46.2934 57.7091 46.3728 57.1568 46.5937C56.6046 46.8147 56.1146 47.1704 55.7303 47.6293C55.6493 47.7388 55.5444 47.8276 55.4237 47.8887C55.303 47.9498 55.17 47.9816 55.0351 47.9814Z" fill="#ED4850"/>
            <path d="M65.8673 94.6C63.9927 91.5631 58.6901 85.1283 49.3432 80.5422L48.7443 80.2517C47.4859 79.6531 46.1581 79.0985 44.9344 78.3679C41.787 81.2726 37.6543 82.8307 33.4005 82.7164C29.1467 82.8307 25.014 81.2726 21.8665 78.3679C20.6429 79.0985 19.315 79.6531 18.0566 80.2517L17.4578 80.5422C8.11089 85.1283 2.80823 91.5631 0.933646 94.6C-1.29677 98.2091 0.768751 100.172 3.97117 101.959C7.26906 103.808 17.3016 107.672 33.4005 107.672C49.4994 107.672 59.5319 103.808 62.8298 101.959C66.0322 100.172 68.0977 98.2091 65.8673 94.6Z" fill="#FFC440"/>
            <path d="M21.1115 78.7903C19.9034 79.4346 18.645 79.9522 17.4578 80.5411C18.586 84.3324 24.077 88.8314 25.811 90.1773C25.9612 90.2947 26.1455 90.3583 26.3352 90.3583C26.5248 90.3583 26.7091 90.2947 26.8594 90.1773C28.2957 89.0656 32.254 85.8641 33.2009 83.5947C33.324 83.3176 33.3919 83.0184 33.4005 82.7145C29.1466 82.8288 25.014 81.2707 21.8665 78.366C21.6148 78.5168 21.3632 78.6582 21.1115 78.7903Z" fill="#F7F0DF"/>
            <path d="M45.6896 78.7904C45.4379 78.6584 45.1862 78.5176 44.9346 78.3679C41.7871 81.2726 37.6544 82.8307 33.4006 82.7164C33.4092 83.0203 33.4771 83.3195 33.6002 83.5967H33.6089C34.5488 85.8625 38.5054 89.0675 39.9443 90.1793C40.0946 90.2966 40.2789 90.3603 40.4685 90.3603C40.6582 90.3603 40.8425 90.2966 40.9927 90.1793C42.7284 88.8334 48.2177 84.3343 49.3459 80.5431C48.1543 79.9524 46.8977 79.4348 45.6896 78.7904Z" fill="#F7F0DF"/>
            <path d="M44.9344 78.3679C41.787 81.2726 37.6543 82.8307 33.4005 82.7164C29.1467 82.8307 25.014 81.2726 21.8666 78.3679C22.1266 78.217 22.3786 78.0525 22.6216 77.8749C24.0357 76.9397 25.1418 75.597 25.798 74.0194C26.0087 73.4963 26.1798 72.9578 26.31 72.4085C26.5532 71.3079 26.6697 70.1825 26.6572 69.0547C29.2174 70.4367 31.7689 71.4578 33.4005 71.4578C35.0321 71.4578 37.5836 70.4367 40.1438 69.0547C40.1327 70.1403 40.2404 71.2238 40.4649 72.2853C40.5934 72.8783 40.7735 73.4586 41.003 74.0194C41.6592 75.597 42.7653 76.9397 44.1794 77.8749C44.4224 78.0525 44.6745 78.217 44.9344 78.3679Z" fill="#FF7364"/>
            <path d="M55.6525 40.5961C55.4426 44.7269 54.7966 48.823 53.7258 52.8142C52.0074 58.8616 49.2997 62.6819 45.8109 65.4283C44.0117 66.776 42.1175 67.9882 40.1437 69.055C37.5835 70.437 35.032 71.4581 33.4004 71.4581C31.7688 71.4581 29.2173 70.437 26.6571 69.055C24.6832 67.9882 22.7891 66.776 20.9899 65.4283C17.4577 62.6555 14.7499 58.8968 13.0489 52.823C12.0232 48.9266 11.4036 44.9318 11.2004 40.9042C10.4098 30.4089 17.1262 20.3158 19.682 17.2533C19.7742 17.1434 19.8918 17.0584 20.0243 17.0059C20.1568 16.9534 20.3 16.935 20.4412 16.9524C20.5825 16.9699 20.7172 17.0226 20.8334 17.1058C20.9496 17.1891 21.0436 17.3002 21.1071 17.4294C23.8599 22.894 30.9235 29.665 40.812 34.848C40.812 34.848 40.812 34.8568 40.8206 34.848C45.5338 37.328 50.5097 39.2564 55.6525 40.5961Z" fill="#FF977B"/>
            <path d="M40.4647 72.2853C38.3894 73.7428 35.9301 74.5339 33.4059 74.5558C30.8818 74.5778 28.4095 73.8297 26.3098 72.4085C26.553 71.3079 26.6695 70.1825 26.657 69.0547C29.2172 70.4367 31.7687 71.4578 33.4003 71.4578C35.0319 71.4578 37.5834 70.4367 40.1436 69.0547C40.1324 70.1403 40.2401 71.2238 40.4647 72.2853Z" fill="#ED4850"/>
            <path d="M25.877 41.6089C25.4564 41.0356 24.8787 40.6005 24.2159 40.3581C23.5103 40.0948 22.7583 39.9842 22.008 40.0332C21.2222 40.0646 20.4455 40.2169 19.7047 40.4848C18.9776 40.8361 18.2308 41.1438 17.4682 41.4064L17.411 41.4197C17.3124 41.4424 17.2096 41.4372 17.1138 41.4045C17.018 41.3719 16.9329 41.313 16.868 41.2345C16.803 41.156 16.7607 41.0608 16.7456 40.9594C16.7306 40.8581 16.7434 40.7544 16.7826 40.66C17.2696 39.7604 18.0831 39.0888 19.0504 38.7877C19.9601 38.3985 20.9287 38.1696 21.9143 38.1107C22.9367 38.0489 23.9603 38.211 24.9154 38.5861C25.921 38.9905 26.7678 39.7189 27.3254 40.6591C27.4207 40.8522 27.4426 41.0741 27.387 41.2826C27.3314 41.491 27.2023 41.6715 27.0241 41.7896C26.8459 41.9078 26.6311 41.9554 26.4205 41.9234C26.21 41.8913 26.0184 41.7819 25.8822 41.616L25.877 41.6089Z" fill="#844040"/>
            <path d="M22.723 45.136H22.7239C23.9567 45.136 24.9561 44.1223 24.9561 42.872V41.916C24.9561 40.6656 23.9567 39.6519 22.7239 39.6519H22.723C21.4903 39.6519 20.4909 40.6656 20.4909 41.916V42.872C20.4909 44.1223 21.4903 45.136 22.723 45.136Z" fill="#9B4E4E"/>
            <path d="M39.4753 40.6591C40.0328 39.719 40.8792 38.9907 41.8845 38.5861C42.8396 38.211 43.8632 38.0489 44.8856 38.1107C45.8712 38.1696 46.8398 38.3985 47.7495 38.7877C48.7168 39.0888 49.5303 39.7604 50.0173 40.66C50.0565 40.7544 50.0693 40.8581 50.0543 40.9594C50.0392 41.0608 49.9969 41.156 49.9319 41.2345C49.867 41.313 49.7819 41.3719 49.6861 41.4045C49.5903 41.4372 49.4875 41.4424 49.3889 41.4197L49.3317 41.4064C48.5691 41.1438 47.8223 40.8361 47.0952 40.4848C46.3544 40.2169 45.5777 40.0646 44.7919 40.0332C44.0416 39.9842 43.2896 40.0948 42.584 40.3581C41.9212 40.6005 41.3435 41.0356 40.9229 41.6089L40.9177 41.6151C40.7858 41.7921 40.5925 41.9118 40.3769 41.9499C40.1614 41.988 39.9396 41.9416 39.7564 41.8202C39.5732 41.6988 39.4423 41.5114 39.3901 41.2959C39.338 41.0803 39.3684 40.8528 39.4753 40.6591Z" fill="#844040"/>
            <path d="M44.0775 39.6528H44.0766C42.8438 39.6528 41.8445 40.6665 41.8445 41.9169V42.8728C41.8445 44.1232 42.8438 45.1369 44.0766 45.1369H44.0775C45.3103 45.1369 46.3096 44.1232 46.3096 42.8728V41.9169C46.3096 40.6665 45.3103 39.6528 44.0775 39.6528Z" fill="#9B4E4E"/>
            <path d="M57.7961 40.297C56.9196 24.8722 53.6217 16.125 42.1971 7.79067C42.099 7.72574 42.0148 7.64133 41.9497 7.54251C41.8846 7.4437 41.8398 7.33253 41.8182 7.21572C41.7966 7.0989 41.7986 6.97885 41.8239 6.86282C41.8493 6.74678 41.8976 6.63715 41.9658 6.54055C42.0341 6.44395 42.1209 6.36236 42.2211 6.30073C42.3213 6.23909 42.4328 6.19866 42.5488 6.1819C42.6648 6.16513 42.7829 6.17237 42.8961 6.20317C43.0093 6.23397 43.1152 6.28771 43.2073 6.36113C59.7983 18.8389 58.7855 34.1704 58.7317 35.4362C58.5512 39.6518 57.7961 40.297 57.7961 40.297Z" fill="#844040"/>
            <path d="M33.3917 52.9814C32.8249 52.9814 31.3522 52.7473 31.3522 51.6787C31.3523 51.4959 31.4085 51.3178 31.513 51.1689C31.6174 51.02 31.765 50.9078 31.9351 50.8479C32.1053 50.7879 32.2897 50.7831 32.4626 50.8342C32.6356 50.8852 32.7886 50.9896 32.9004 51.1329C33.2179 51.2508 33.5663 51.2508 33.8837 51.1329C33.9957 50.9899 34.1487 50.8858 34.3216 50.835C34.4945 50.7842 34.6787 50.7891 34.8487 50.8492C35.0187 50.9092 35.1661 51.0214 35.2705 51.1702C35.3748 51.319 35.431 51.497 35.4311 51.6795C35.4311 52.9462 33.4125 52.9814 33.3917 52.9814Z" fill="#ED4850"/>
            <path d="M33.4091 57.4176C32.1678 57.407 30.9374 57.1813 29.771 56.7504C29.5772 56.6416 29.4318 56.4618 29.3645 56.2478C29.2972 56.0338 29.3131 55.8018 29.409 55.5993C29.5048 55.3969 29.6733 55.2392 29.88 55.1586C30.0867 55.078 30.3159 55.0806 30.5208 55.1659C32.3902 55.8237 34.4236 55.8237 36.293 55.1659C36.5001 55.064 36.7387 55.0497 36.9562 55.1262C37.1738 55.2028 37.3524 55.3638 37.4529 55.5739C37.5534 55.784 37.5674 56.026 37.492 56.2466C37.4165 56.4673 37.2578 56.6485 37.0506 56.7504C35.8838 57.184 34.6518 57.4097 33.4091 57.4176Z" fill="#ED4850"/>
            <path d="M28.458 36.6543C28.3461 36.6541 28.2353 36.632 28.1317 36.5891H28.1256C26.1061 35.752 19.9616 33.2072 15.802 36.4729C15.7121 36.5463 15.6086 36.6008 15.4977 36.6332C15.3868 36.6655 15.2707 36.6752 15.1561 36.6614C15.0414 36.6477 14.9307 36.611 14.8302 36.5533C14.7298 36.4956 14.6417 36.4182 14.5711 36.3256C14.5006 36.233 14.4489 36.1271 14.4192 36.014C14.3894 35.9008 14.3823 35.7829 14.398 35.6669C14.4138 35.5509 14.4522 35.4393 14.511 35.3386C14.5699 35.2379 14.6479 35.1501 14.7406 35.0804C19.6952 31.1949 26.5366 34.024 28.7861 34.9589C28.9738 35.0347 29.1295 35.1746 29.2264 35.3544C29.3233 35.5341 29.3553 35.7426 29.317 35.9438C29.2786 36.1449 29.1723 36.3262 29.0163 36.4564C28.8603 36.5866 28.6644 36.6575 28.4624 36.6569L28.458 36.6543Z" fill="#844040"/>
            <path d="M38.343 36.6538C38.4549 36.6536 38.5657 36.6315 38.6693 36.5886H38.6754C40.6949 35.7515 46.8394 33.2067 50.9991 36.4724C51.089 36.5458 51.1924 36.6003 51.3033 36.6327C51.4142 36.6651 51.5304 36.6747 51.645 36.6609C51.7596 36.6472 51.8704 36.6105 51.9708 36.5528C52.0713 36.4951 52.1593 36.4177 52.2299 36.3251C52.3005 36.2325 52.3522 36.1266 52.3819 36.0135C52.4116 35.9004 52.4188 35.7824 52.403 35.6664C52.3872 35.5505 52.3488 35.4389 52.29 35.3381C52.2312 35.2374 52.1532 35.1496 52.0605 35.0799C47.1059 31.1944 40.2645 34.0236 38.015 34.9584C37.8273 35.0342 37.6716 35.1741 37.5747 35.3539C37.4777 35.5337 37.4457 35.7421 37.484 35.9433C37.5224 36.1445 37.6287 36.3258 37.7848 36.4559C37.9408 36.5861 38.1367 36.657 38.3387 36.6564L38.343 36.6538Z" fill="#844040"/>
            </svg>
        </div>
    )
}

export default ParentPersona
