import React from 'react';
import ReactDOM from 'react-dom';
import Link from '../with-last-visited/Link';
import Logo from '../../images/AdobeLogo.svg'
import './AdobeHeader.scss';

export default props => {
    return ReactDOM.createPortal(
        <header className="adobe-header">
            <Link className="adobe-header__logo-container" to="/">
                <img className="adobe-logo" src={ Logo } alt="Red Adobe Logo Horizontal"/>
            </Link>
        </header>,
        document.querySelector("#adobe-root")
    );
}