import React from 'react';
import { useHistory } from 'react-router-dom';

const JourneySelector = ({ onSelect = () => {} }) => {
    const history = useHistory();

    const routingMap = (pathname, newPersona) => {
        const introPaths = ['/chloe/02','/chloe/04','/james/02','/james/04','/ruby/02','/ruby/03','/taylor/02','/taylor/03'];
        const introDestinations = { chloe: '/chloe/04', james: '/james/04', ruby: '/ruby/03', taylor: '/taylor/03' };
        const endPaths = ['/chloe/16', '/james/21', '/ruby/16', '/taylor/15'];
        const endDestinations = { chloe: '/chloe/16', james: '/james/21', ruby: '/ruby/16', taylor: '/taylor/16' };
        const defaultDestinations = { chloe: '/chloe/09', james: '/james/10', ruby: '/ruby/08', taylor: '/taylor/06' };

        if (introPaths.includes(pathname)) {
            return introDestinations[newPersona];
        } else if (endPaths.includes(pathname)) {
            return endDestinations[newPersona];
        } else {
            return defaultDestinations[newPersona]
        }
    }    

    const changePersona = newPersona => {
        const intervalId = setInterval(() => {
            if (window.digitalData) {
                clearInterval(intervalId);
    
                window.digitalData._set( 
                    'digitalData.primaryEvent.eventInfo.eventName', `${newPersona} Pop Up Journey Select Switch` 
                ); 
                window._satellite.track('event', { digitalData: window.digitalData._snapshot() }); 
                window.digitalData._delete('digitalData.primaryEvent.eventInfo.eventName'); 
            }
        }, 100)

        if (window.location.pathname === "/") {
            history.push(`/${newPersona}/02`)
        } else {
            history.push(routingMap(window.location.pathname, newPersona))
        }
    }

    return (
        <div className="journey-select">
            <select defaultValue={'DEFAULT'} placeholder="Select a Profile to swap" className="journey-select" onChange={(e) => { changePersona(e.target.value); onSelect(); }}>
                <option className="journey-option" value="DEFAULT" disabled >Select a Profile to swap</option>
                <option className="journey-option" value="chloe" label="Chloe (Student)">Chloe (Student)</option>
                <option className="journey-option" value="james" label="James (Parent)">James (Parent)</option>
                <option className="journey-option" value="ruby" label="Ruby (Teacher)">Ruby (Teacher)</option>
                <option className="journey-option" value="taylor" label="Taylor (Staff)">Taylor (Staff)</option>
            </select>
        </div>
    )
}

export default JourneySelector
