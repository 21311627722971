import React from 'react'

function StudentPersona(props) {
    return (
        <div {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="69" height="108" viewBox="0 0 69 89" fill="none">
            <path d="M13.9242 57.1425C10.5523 58.4191 5.8509 55.5138 4.58311 52.1418C3.4738 49.1836 4.06367 46.4104 6.2823 44.9489C7.97268 43.822 10.6403 43.9804 11.7848 44.2005C15.3399 44.9577 18.6414 53.4343 13.9242 57.1425Z" fill="#FF7364"/>
            <path d="M62.4346 44.9576C60.7442 43.8307 58.0589 43.9803 56.9144 44.2004C56.9144 44.2004 48.9326 50.2427 54.775 57.1424C58.147 58.419 62.8395 55.5137 64.1161 52.1417C65.2166 49.1923 64.6356 46.4279 62.4346 44.9576Z" fill="#FF7364"/>
            <path d="M12.56 51.649C12.4195 51.6492 12.2809 51.6157 12.1559 51.5514C12.0309 51.4871 11.9232 51.3937 11.8416 51.2792C11.1425 50.2825 10.0816 49.5985 8.88522 49.3731C8.77018 49.3612 8.65862 49.3268 8.55689 49.2718C8.45517 49.2167 8.36528 49.1422 8.29235 49.0524C8.14508 48.8712 8.07585 48.6388 8.0999 48.4065C8.12395 48.1741 8.23931 47.9609 8.4206 47.8136C8.60189 47.6663 8.83425 47.5971 9.06659 47.6211C9.90888 47.7568 10.7154 48.0601 11.4384 48.513C12.1614 48.9659 12.7862 49.5593 13.2758 50.258C13.3695 50.3895 13.4252 50.5444 13.4368 50.7055C13.4483 50.8666 13.4154 51.0278 13.3415 51.1714C13.2676 51.315 13.1556 51.4356 13.0177 51.5198C12.8799 51.604 12.7216 51.6487 12.56 51.649Z" fill="#ED4850"/>
            <path d="M56.1386 51.6575C55.9773 51.6575 55.8192 51.6131 55.6814 51.5293C55.5436 51.4455 55.4315 51.3255 55.3573 51.1823C55.2831 51.0392 55.2496 50.8784 55.2606 50.7175C55.2715 50.5566 55.3264 50.4018 55.4193 50.27C55.9072 49.5676 56.5319 48.9709 57.2559 48.5157C57.9799 48.0605 58.7884 47.7561 59.6329 47.6209C59.8652 47.5975 60.0973 47.6674 60.2781 47.8152C60.4589 47.963 60.5736 48.1765 60.597 48.4088C60.6203 48.6412 60.5504 48.8733 60.4026 49.0541C60.2549 49.2348 60.0413 49.3495 59.809 49.3729C59.2155 49.4815 58.6495 49.7066 58.1435 50.0353C57.6376 50.364 57.2018 50.7897 56.8614 51.2878C56.7794 51.4029 56.6709 51.4966 56.5451 51.5609C56.4193 51.6253 56.2799 51.6584 56.1386 51.6575Z" fill="#ED4850"/>
            <path d="M4.39783 37.2186C4.39783 37.2186 5.34867 42.8972 6.2819 44.9397V44.9485C28.8203 44.9485 62.4342 44.9573 62.4342 44.9573C63.3762 42.9148 64.3183 37.2186 64.3183 37.2186C47.639 18.2722 20.3464 18.7476 4.39783 37.2186Z" fill="#5C4666"/>
            <path d="M67.48 94.2727C63.9921 89.3952 59.3868 85.4239 54.0494 82.691C49.9432 80.1959 46.065 80.0498 43.5136 78.127C40.8125 80.0973 37.8209 81.6063 34.3812 81.7269C31.2117 81.8379 26.4901 81.3008 23.7749 78.9704C21.3098 80.1642 18.0575 80.6194 14.6486 82.691C9.31178 85.4239 4.70708 89.3953 1.21977 94.2727C-1.36951 97.7116 0.477588 99.8334 3.82049 101.616C9.48414 104.64 22.5397 107.634 34.323 107.634C46.1064 107.634 59.2165 104.64 64.8837 101.616C68.2231 99.8334 70.0675 97.7116 67.48 94.2727Z" fill="#5981D1"/>
            <path d="M47.9341 80.1037C39.2973 93.706 16.3892 89.6561 16.3892 81.7589C21.3546 79.3994 25.7303 79.7251 27.4383 74.9709C27.7791 73.9976 28.0008 72.9866 28.0986 71.96H40.6003C40.693 72.9453 40.8968 73.917 41.2078 74.8565C42.3787 78.2284 44.8527 79.1001 47.9341 80.1037Z" fill="#FF7364"/>
            <path d="M41.2074 74.8565C39.1183 76.0652 36.7513 76.7112 34.3377 76.7312C31.9242 76.7513 29.5468 76.1448 27.4379 74.9709C27.7787 73.9976 28.0004 72.9866 28.0982 71.96H40.5999C40.6926 72.9453 40.8964 73.917 41.2074 74.8565Z" fill="#ED4850"/>
            <path d="M57.0295 33.8628C57.1616 13.4303 11.6586 13.7235 11.6586 33.9262C11.2352 41.7354 11.9998 49.5637 13.9266 57.1434C15.999 64.5221 22.2719 69.8749 28.1011 71.9553C30.0946 72.6959 32.197 73.1011 34.3229 73.1544C36.4678 73.1015 38.5894 72.6964 40.6029 71.9553C46.4294 69.8749 52.7023 64.5221 54.7774 57.1434C56.6999 49.5415 57.4592 41.6922 57.0295 33.8628Z" fill="#FF977B"/>
            <path d="M68.4776 30.7163C67.5021 16.9626 52.7403 0.279785 34.3918 0.279785H34.3275C15.9789 0.279785 1.21718 16.9626 0.240805 30.7163C-0.0136327 34.2503 1.7375 37.0764 4.40072 37.2216C7.83431 37.2216 9.78176 36.3086 11.2881 34.4413C11.5079 34.1694 11.7024 33.8779 11.8692 33.5706C11.9174 33.485 11.988 33.4142 12.0735 33.3658C12.159 33.3175 12.256 33.2934 12.3542 33.2962C12.4524 33.299 12.5479 33.3286 12.6305 33.3818C12.713 33.435 12.7795 33.5097 12.8227 33.5979C13.4214 34.8164 14.9788 36.1185 19.004 35.364C20.236 35.1925 21.4014 34.701 22.3841 33.9385C23.3667 33.1759 24.1323 32.1691 24.6043 31.0183C24.6419 30.9247 24.7048 30.8434 24.786 30.7836C24.8672 30.7237 24.9635 30.6877 25.064 30.6794C25.1646 30.6712 25.2654 30.6911 25.3553 30.737C25.4451 30.7828 25.5204 30.8528 25.5727 30.9391C26.9585 33.251 31.0092 34.7169 34.316 34.7169C37.6229 34.7169 41.7388 33.2466 43.1386 30.9311C43.191 30.8447 43.2665 30.7747 43.3566 30.7289C43.4467 30.6831 43.5478 30.6634 43.6485 30.672C43.7492 30.6806 43.8455 30.7171 43.9265 30.7776C44.0075 30.838 44.0701 30.9198 44.107 31.0139C44.5784 32.1664 45.3441 33.175 46.3275 33.9388C47.3109 34.7027 48.4775 35.195 49.7108 35.3666C53.7352 36.1211 55.2935 34.819 55.8922 33.6005C55.9353 33.5123 56.0018 33.4376 56.0844 33.3844C56.167 33.3313 56.2625 33.3017 56.3607 33.2989C56.4588 33.2961 56.5559 33.3201 56.6414 33.3685C56.7268 33.4168 56.7975 33.4876 56.8456 33.5732C57.0124 33.8803 57.2066 34.1718 57.4258 34.4439C58.9331 36.3113 60.8814 37.2243 64.3132 37.2243C66.9782 37.0764 68.7329 34.2503 68.4776 30.7163Z" fill="#4B3956"/>
            <path d="M48.9592 37.5706C47.8165 36.9056 46.5518 36.4772 45.2402 36.3108C43.9286 36.1444 42.5969 36.2435 41.3243 36.6022C41.0686 36.6853 40.852 36.8589 40.7152 37.0904C40.5784 37.3219 40.5308 37.5953 40.5813 37.8594C40.6301 38.1355 40.7818 38.3828 41.0058 38.5515C41.2298 38.7201 41.5095 38.7975 41.7883 38.768C43.6069 38.5209 45.4539 38.5753 47.2548 38.9291C48.5261 39.2769 49.0569 38.9899 49.2718 38.6271C49.3653 38.4446 49.3851 38.2331 49.3269 38.0364C49.2687 37.8397 49.137 37.6729 48.9592 37.5706Z" fill="#4B3956"/>
            <path d="M44.4547 42.5586H44.4538C43.2965 42.5586 42.3584 43.4967 42.3584 44.654V45.4921C42.3584 46.6493 43.2965 47.5875 44.4538 47.5875H44.4547C45.6119 47.5875 46.55 46.6493 46.55 45.4921V44.654C46.55 43.4967 45.6119 42.5586 44.4547 42.5586Z" fill="#5C4666"/>
            <path d="M19.681 37.5706C20.8237 36.9056 22.0885 36.4772 23.4001 36.3108C24.7117 36.1444 26.0434 36.2435 27.3159 36.6022C27.5715 36.6855 27.7878 36.8592 27.9245 37.0906C28.0611 37.3221 28.1086 37.5954 28.0581 37.8594C28.0095 38.1356 27.8578 38.3831 27.6337 38.5517C27.4097 38.7204 27.1299 38.7977 26.8511 38.768C25.0325 38.5209 23.1855 38.5754 21.3846 38.9291C20.1133 39.2769 19.5833 38.9899 19.3676 38.6271C19.2741 38.4445 19.2545 38.2329 19.3129 38.0362C19.3712 37.8395 19.5031 37.6728 19.681 37.5706Z" fill="#4B3956"/>
            <path d="M24.1849 47.5886H24.1858C25.343 47.5886 26.2811 46.6505 26.2811 45.4933V44.6551C26.2811 43.4979 25.343 42.5597 24.1858 42.5597H24.1849C23.0276 42.5597 22.0895 43.4979 22.0895 44.6551V45.4933C22.0895 46.6505 23.0276 47.5886 24.1849 47.5886Z" fill="#5C4666"/>
            <path d="M30.1165 89.924C27.7876 89.9308 25.4693 89.6095 23.23 88.9696C18.3948 87.5609 15.5079 84.8687 15.5079 81.7591C15.5079 81.5256 15.6007 81.3016 15.7658 81.1365C15.9309 80.9714 16.1548 80.8787 16.3883 80.8787C16.6218 80.8787 16.8458 80.9714 17.0109 81.1365C17.176 81.3016 17.2687 81.5256 17.2687 81.7591C17.2687 84.0067 19.7409 86.1215 23.7212 87.2783C30.039 89.114 41.5002 88.5928 47.1902 79.632C47.3208 79.4474 47.5174 79.3201 47.7392 79.2765C47.9611 79.2329 48.1912 79.2764 48.3819 79.3978C48.5726 79.5192 48.7093 79.7094 48.7636 79.9289C48.818 80.1483 48.7858 80.3803 48.6737 80.5767C44.5358 87.0987 37.1193 89.924 30.1165 89.924Z" fill="white"/>
            <path d="M41.5325 46.2168C41.3105 46.2175 41.0965 46.1344 40.9333 45.984C40.77 45.8337 40.6696 45.6272 40.6521 45.406C40.628 45.0223 40.6802 44.6376 40.8057 44.2742C40.9311 43.9108 41.1272 43.5758 41.3828 43.2886C42.2215 42.4198 43.3488 41.8873 44.5526 41.7911C45.7563 41.695 46.9538 42.0419 47.9198 42.7665C48.2177 43.0089 48.4645 43.3081 48.6459 43.6466C48.8272 43.9851 48.9396 44.3563 48.9763 44.7386C48.9947 44.9714 48.92 45.202 48.7684 45.3797C48.6168 45.5573 48.4009 45.6675 48.1681 45.686C47.9353 45.7044 47.7047 45.6296 47.527 45.478C47.3494 45.3265 47.2392 45.1105 47.2208 44.8777C47.1817 44.5731 47.0234 44.2964 46.7806 44.1083C46.1706 43.686 45.4334 43.4875 44.6939 43.5466C43.9544 43.6056 43.258 43.9185 42.7228 44.4323C42.5124 44.6584 42.3995 44.9582 42.4085 45.2669C42.4176 45.3821 42.404 45.4981 42.3683 45.6081C42.3326 45.7181 42.2756 45.82 42.2006 45.908C42.1255 45.9959 42.0339 46.0682 41.9309 46.1208C41.8279 46.1733 41.7155 46.2051 41.6002 46.2142L41.5325 46.2168Z" fill="#4B3956"/>
            <path d="M27.1035 46.217C27.0798 46.217 27.0569 46.217 27.0331 46.217C26.9178 46.2078 26.8055 46.1761 26.7025 46.1236C26.5995 46.071 26.5078 45.9987 26.4328 45.9107C26.3577 45.8227 26.3007 45.7209 26.2651 45.6109C26.2294 45.5009 26.2157 45.3849 26.2249 45.2696C26.2341 44.9607 26.1208 44.6607 25.9097 44.435C25.3929 43.8977 24.6887 43.5805 23.9438 43.5493C22.6496 43.4393 21.4839 44.0538 21.4117 44.8867C21.4045 45.0039 21.3738 45.1184 21.3216 45.2236C21.2694 45.3288 21.1967 45.4224 21.1078 45.4991C21.0189 45.5757 20.9155 45.6338 20.8038 45.67C20.6921 45.7061 20.5742 45.7195 20.4573 45.7094C20.3403 45.6993 20.2265 45.6659 20.1226 45.6111C20.0187 45.5564 19.9269 45.4815 19.8524 45.3907C19.7779 45.2999 19.7223 45.1952 19.6889 45.0826C19.6556 44.97 19.645 44.8519 19.6579 44.7352C19.8164 42.8934 21.7612 41.6089 24.0846 41.7938C25.2949 41.8579 26.4315 42.3955 27.2488 43.2905C27.5048 43.5774 27.7013 43.9124 27.8269 44.2758C27.9525 44.6393 28.0047 45.0241 27.9804 45.4079C27.9625 45.6282 27.8624 45.8337 27.6999 45.9836C27.5375 46.1335 27.3246 46.2168 27.1035 46.217Z" fill="#4B3956"/>
            <path d="M35.5996 52.9608C35.4422 52.9609 35.2876 52.9189 35.152 52.839C35.0163 52.7591 34.9046 52.6442 34.8284 52.5065C34.6713 52.4229 34.4961 52.3792 34.3182 52.3792C34.1403 52.3792 33.9651 52.4229 33.808 52.5065C33.7135 52.6773 33.5649 52.8118 33.3857 52.889C33.2064 52.9661 33.0066 52.9815 32.8176 52.9327C32.6286 52.8839 32.4612 52.7738 32.3417 52.6195C32.2221 52.4652 32.1573 52.2755 32.1572 52.0804C32.1572 50.9658 33.742 50.6189 34.323 50.6189C34.9041 50.6189 36.48 50.9658 36.48 52.0804C36.48 52.3139 36.3873 52.5378 36.2222 52.7029C36.057 52.868 35.8331 52.9608 35.5996 52.9608Z" fill="#ED4850"/>
            <path d="M34.3362 58.3312C33.2126 58.318 32.1089 58.0331 31.1192 57.501C30.9116 57.3939 30.7551 57.2088 30.684 56.9863C30.6129 56.7638 30.6331 56.5222 30.7402 56.3146C30.8472 56.107 31.0324 55.9505 31.2549 55.8794C31.4773 55.8083 31.7189 55.8285 31.9265 55.9356C32.6547 56.3484 33.4775 56.5655 34.3146 56.5655C35.1517 56.5655 35.9745 56.3484 36.7027 55.9356C36.8052 55.8787 36.918 55.8427 37.0344 55.83C37.1509 55.8172 37.2688 55.8278 37.3812 55.8611C37.4935 55.8945 37.5981 55.95 37.6887 56.0243C37.7793 56.0986 37.8542 56.1902 37.909 56.2939C37.9637 56.3975 37.9972 56.511 38.0075 56.6278C38.0178 56.7445 38.0047 56.8621 37.9689 56.9737C37.9332 57.0854 37.8755 57.1887 37.7993 57.2777C37.723 57.3667 37.6298 57.4397 37.525 57.4922C36.5475 58.0305 35.4521 58.3188 34.3362 58.3312Z" fill="#ED4850"/>
            </svg>
        </div>
    )
}

export default StudentPersona
