import React, { useRef } from 'react';
import './Lightbox.scss'
import LightboxToolTip from './LightboxToolTip'

export default function Lightbox({ isvisible, large, faas, hotspot, className = '', children, onOutsideClick = () => {} }) {
    const toolTipRef = useRef(null);
    
    const handleOutsideClick = event => {
        if (toolTipRef.current && event.target.contains(toolTipRef.current)) {
            onOutsideClick();
        }
    }

    return (
        <div onClick={handleOutsideClick} className={`lightbox ${className} ${!isvisible ? 'hidden' : ''}`}>
            <LightboxToolTip ref={toolTipRef} large={large} hotspot={hotspot} faas={faas} onOutsideClick={handleOutsideClick}>
                {children}
            </LightboxToolTip>
        </div>
    )
}
