import React, { useState } from 'react'
import Lightbox from '../lightbox/Lightbox';

export default function ProgressAnimation(props) {

    const [lightbox, showLightbox] = useState(false);

    return (
        <div className="progress-animation" onClick={() => showLightbox(!lightbox)}>
            <Lightbox hotspot onOutsideClick={() => showLightbox(false)} isvisible={lightbox}>
                {props.children}
            </Lightbox>
        </div>
    )
}
