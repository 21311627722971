import React from 'react'

function StaffPersona(props) {
    return (
        <div {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="76" height="108" viewBox="0 0 76 89" fill="none">
            <path d="M67.6875 28.1609C62.9605 9.81632 43.6916 -7.65684 27.618 3.50486C27.2901 3.73927 26.9192 3.90677 26.5265 3.9978C18.5514 5.84635 11.2188 12.1138 8.04988 27.4303C6.82631 33.3369 -2.59246 39.7716 0.690904 52.4033C3.4426 62.9831 16.8252 70.3078 29.9692 71.8306C34.4192 73.0137 39.0949 73.0618 43.5683 71.9706C57.2722 70.9565 71.7455 63.4743 74.6239 52.405C77.9073 39.7716 69.1927 34.0059 67.6875 28.1609Z" fill="#F4E9D0"/>
            <path d="M47.1688 79.7353C44.7913 81.1188 42.1097 81.8958 39.3609 81.9976C35.688 82.0816 32.066 81.1266 28.9122 79.2424C30.5639 77.8929 31.6197 75.9489 31.8522 73.8288C31.9449 73.2308 32.0066 72.6285 32.0371 72.0242L43.322 71.989C43.3528 72.5699 43.4145 73.1488 43.5069 73.7231C43.618 74.9383 44.0057 76.1119 44.6405 77.154C45.2752 78.1961 46.14 79.0791 47.1688 79.7353Z" fill="#FF977B"/>
            <path d="M43.5068 73.7231C41.7464 74.8437 39.6955 75.4223 37.6091 75.3868C35.5822 75.4156 33.5879 74.8758 31.8522 73.8288C31.9448 73.2308 32.0065 72.6285 32.037 72.0242L43.322 71.989C43.3527 72.5699 43.4144 73.1488 43.5068 73.7231Z" fill="#FF7364"/>
            <path d="M15.8931 44.2984C12.0419 42.4252 9.53231 44.2103 9.08513 44.5624C6.88449 46.323 6.63185 49.3291 7.57901 51.844C8.84482 55.2268 13.1414 57.8297 16.5321 56.5912C16.5321 56.5912 18.8912 54.931 18.4828 50.4822C18.2627 48.0861 15.8931 44.2984 15.8931 44.2984Z" fill="#FF977B"/>
            <path d="M59.4659 44.2984C63.317 42.4252 65.8266 44.2103 66.2738 44.5624C68.4744 46.323 68.7271 49.3291 67.7799 51.844C66.5141 55.2268 62.2175 57.8297 58.8268 56.5912C58.8268 56.5912 56.4677 54.931 56.8761 50.4822C57.0962 48.0861 59.4659 44.2984 59.4659 44.2984Z" fill="#FF977B"/>
            <path d="M34.5202 8.91431C13.8024 8.91431 14.2426 37.3898 15.173 48.327C15.4359 51.111 15.89 53.8737 16.5321 56.5953C18.6104 65.6532 30.03 73.0993 37.6355 73.0993C45.2409 73.0993 56.6605 65.6532 58.7388 56.5953C59.1094 54.9792 59.9606 50.9978 60.4113 47.4811C61.8866 35.962 65.0626 8.91431 34.5202 8.91431Z" fill="#FFBD99"/>
            <path d="M60.1173 17.9733C62.5644 23.378 62.987 25.6773 62.987 34.7713C62.987 40.669 61.4738 44.4268 64.5987 46.9611C65.7897 48.0905 64.5653 49.6204 62.4421 48.9619C53.4881 46.1891 52.952 38.3002 49.2109 26.5769C47.0983 19.9512 39.6284 10.4259 31.2633 16.4478C22.3876 16.5437 23.5249 26.8137 21.8859 35.4226C20.8137 41.0563 18.5198 46.918 11.4328 51.0156C11.1341 51.1749 10.7897 51.2269 10.4572 51.1628C10.1247 51.0987 9.82437 50.9223 9.60634 50.6633C9.38831 50.4042 9.26584 50.0782 9.25942 49.7396C9.253 49.4011 9.36301 49.0706 9.57106 48.8035C13.7893 42.9198 8.55788 27.8762 15.8139 18.0463C15.8139 18.0463 18.4247 12.9945 22.8031 10.6372C34.5203 4.32923 44.0174 6.676 49.2461 9.61079C54.4749 12.5456 58.7027 14.8492 60.1173 17.9733Z" fill="#F4E9D0"/>
            <path d="M65.7774 102.121C62.6348 103.82 53.0224 107.393 37.6795 107.393C22.3366 107.393 12.7241 103.82 9.5816 102.121C6.5359 100.466 4.56412 98.6525 6.69434 95.3163C8.51648 92.4554 12.7153 87.4644 21.7204 82.7022C25.4527 80.7304 29.9244 80.4751 31.4913 75.3872C35.2148 78.6354 41.9928 77.315 43.9733 75.6513C45.6106 80.4575 49.9855 80.7656 53.6386 82.7022C62.6436 87.4644 66.8425 92.4554 68.6646 95.3163C70.7949 98.6525 68.8231 100.466 65.7774 102.121Z" fill="#5C4666"/>
            <path d="M12.357 58.1941C13.6701 58.1941 14.7345 57.1296 14.7345 55.8165C14.7345 54.5034 13.6701 53.439 12.357 53.439C11.0438 53.439 9.97937 54.5034 9.97937 55.8165C9.97937 57.1296 11.0438 58.1941 12.357 58.1941Z" fill="white"/>
            <path d="M62.8266 58.1941C64.1397 58.1941 65.2041 57.1296 65.2041 55.8165C65.2041 54.5034 64.1397 53.439 62.8266 53.439C61.5135 53.439 60.449 54.5034 60.449 55.8165C60.449 57.1296 61.5135 58.1941 62.8266 58.1941Z" fill="white"/>
            <path d="M54.399 48.7379C51.6764 48.914 46.3508 48.8154 43.5657 48.7379C43.0103 48.7163 42.4794 48.5037 42.0625 48.1362C41.6456 47.7687 41.3681 47.2686 41.277 46.7203C40.9927 45.3058 40.8383 43.8682 40.8158 42.4256C40.7976 41.7942 41.0266 41.1807 41.4541 40.7157C41.8816 40.2507 42.4738 39.971 43.1044 39.9362C47.0199 39.7042 50.9457 39.7042 54.8612 39.9362C55.4903 39.9754 56.0798 40.2564 56.5064 40.7204C56.933 41.1844 57.1636 41.7954 57.1499 42.4256C57.1346 43.8687 56.9801 45.3069 56.6886 46.7203C56.5813 47.2613 56.299 47.752 55.8853 48.1166C55.4715 48.4812 54.9492 48.6995 54.399 48.7379Z" fill="#FFD5C5"/>
            <path d="M20.9598 48.7379C23.6824 48.914 29.008 48.8154 31.7931 48.7379C32.3485 48.7163 32.8794 48.5037 33.2963 48.1362C33.7132 47.7687 33.9907 47.2686 34.0818 46.7203C34.3661 45.3058 34.5205 43.8682 34.543 42.4256C34.5612 41.7942 34.3322 41.1807 33.9047 40.7157C33.4772 40.2507 32.885 39.971 32.2544 39.9362C28.3389 39.7042 24.4131 39.7042 20.4976 39.9362C19.8685 39.9754 19.279 40.2564 18.8524 40.7204C18.4258 41.1844 18.1952 41.7954 18.209 42.4256C18.2242 43.8687 18.3787 45.3069 18.6702 46.7203C18.7775 47.2613 19.0598 47.752 19.4735 48.1166C19.8873 48.4812 20.4096 48.6995 20.9598 48.7379Z" fill="#FFD5C5"/>
            <path d="M7.62646 46.4077C8.57682 43.6856 9.13406 40.8419 9.28135 37.9624C9.36938 31.5295 10.8236 23.4029 15.1351 18.1733C15.2079 18.0819 15.2981 18.0059 15.4006 17.9497C15.5031 17.8935 15.6157 17.8583 15.7319 17.8461C15.8482 17.8339 15.9656 17.845 16.0775 17.8787C16.1894 17.9124 16.2935 17.968 16.3837 18.0423C16.4739 18.1167 16.5483 18.2082 16.6028 18.3116C16.6572 18.4151 16.6905 18.5283 16.7007 18.6447C16.7109 18.7611 16.6978 18.8784 16.6622 18.9897C16.6266 19.101 16.5692 19.2041 16.4933 19.293C12.5753 24.0464 11.8332 31.1219 11.7487 37.2653C11.6886 39.3817 11.5167 41.4934 11.2338 43.5917C10.4701 43.7853 9.75506 44.1356 9.13405 44.6204C8.51303 45.1052 7.99963 45.7138 7.62646 46.4077Z" fill="#E2C7A3"/>
            <path d="M66.367 44.643C65.3239 43.8281 64.0262 43.4084 62.7034 43.4582C61.9279 40.306 62.2712 28.6698 57.5979 19.5961C57.5421 19.4945 57.507 19.3828 57.4945 19.2675C57.482 19.1522 57.4924 19.0356 57.5252 18.9244C57.5579 18.8131 57.6122 18.7095 57.6852 18.6193C57.7581 18.5291 57.8481 18.4543 57.95 18.399C58.158 18.304 58.3936 18.2882 58.6124 18.3547C58.8312 18.4212 59.0183 18.5652 59.1383 18.7599C64.7474 25.8196 62.3266 38.4011 66.367 44.643Z" fill="#E2C7A3"/>
            <path d="M24.2908 42.6752V43.7614C24.2666 44.063 24.305 44.3663 24.4038 44.6522C24.5025 44.9381 24.6594 45.2005 24.8646 45.4228C25.0698 45.6451 25.3188 45.8226 25.5959 45.9439C25.873 46.0653 26.1722 46.1279 26.4747 46.1279C26.7772 46.1279 27.0765 46.0653 27.3536 45.9439C27.6307 45.8226 27.8797 45.6451 28.0848 45.4228C28.29 45.2005 28.4469 44.9381 28.5457 44.6522C28.6444 44.3663 28.6829 44.063 28.6587 43.7614V42.6752C28.682 42.0718 28.4653 41.4837 28.056 41.0397C27.6466 40.5957 27.0781 40.332 26.4747 40.3064C26.1829 40.3073 25.8944 40.3693 25.6279 40.4883C25.3614 40.6073 25.1227 40.7807 24.9272 40.9974C24.5163 41.4595 24.2897 42.0567 24.2908 42.6752Z" fill="#9B4E4E"/>
            <path d="M51.0681 42.6752V43.7614C51.0924 44.063 51.0539 44.3663 50.9551 44.6522C50.8564 44.9381 50.6995 45.2005 50.4943 45.4228C50.2892 45.6451 50.0402 45.8226 49.7631 45.9439C49.486 46.0653 49.1867 46.1279 48.8842 46.1279C48.5817 46.1279 48.2825 46.0653 48.0053 45.9439C47.7282 45.8226 47.4793 45.6451 47.2741 45.4228C47.0689 45.2005 46.912 44.9381 46.8133 44.6522C46.7145 44.3663 46.676 44.063 46.7003 43.7614V42.6752C46.6769 42.0718 46.8936 41.4837 47.303 41.0397C47.7123 40.5957 48.2809 40.332 48.8842 40.3064C49.1761 40.3073 49.4645 40.3693 49.731 40.4883C49.9975 40.6073 50.2362 40.7807 50.4317 40.9974C50.8427 41.4595 51.0692 42.0567 51.0681 42.6752Z" fill="#9B4E4E"/>
            <path d="M34.5201 43.3101C34.3066 43.3107 34.1002 43.2337 33.9393 43.0934C33.7783 42.9531 33.6739 42.7591 33.6454 42.5475C33.6168 42.3359 33.6662 42.1212 33.7842 41.9433C33.9022 41.7654 34.0809 41.6364 34.2869 41.5804C36.4912 40.9699 38.82 40.9699 41.0244 41.5804C41.1359 41.6108 41.2404 41.6628 41.3319 41.7335C41.4233 41.8042 41.4999 41.8923 41.5574 41.9926C41.6148 42.0929 41.6519 42.2036 41.6665 42.3183C41.6812 42.4329 41.6731 42.5494 41.6428 42.6609C41.6124 42.7725 41.5604 42.877 41.4897 42.9684C41.419 43.0599 41.3309 43.1365 41.2306 43.1939C41.1302 43.2513 41.0196 43.2884 40.9049 43.3031C40.7902 43.3177 40.6738 43.3096 40.5622 43.2793C38.6615 42.7527 36.6533 42.7527 34.7525 43.2793C34.6768 43.2997 34.5986 43.3101 34.5201 43.3101Z" fill="#EA3D5B"/>
            <path d="M22.803 42.2978C22.6466 42.2975 22.493 42.2557 22.3582 42.1764C22.2233 42.0972 22.112 41.9834 22.0357 41.8469C21.9594 41.7103 21.9209 41.5559 21.9241 41.3995C21.9273 41.2431 21.9721 41.0904 22.0539 40.9571C22.4321 40.4063 22.924 39.9429 23.4965 39.5984C24.0689 39.2538 24.7087 39.0361 25.3725 38.9598C26.1813 38.8158 27.0116 38.8455 27.8081 39.0471C28.6046 39.2486 29.3491 39.6173 29.9921 40.1288C30.0804 40.2035 30.1531 40.2948 30.2061 40.3976C30.2592 40.5004 30.2914 40.6126 30.3011 40.7279C30.3107 40.8431 30.2976 40.9591 30.2624 41.0693C30.2272 41.1795 30.1706 41.2816 30.0959 41.37C30.0213 41.4583 29.9299 41.531 29.8271 41.584C29.7243 41.637 29.6121 41.6693 29.4969 41.6789C29.3816 41.6886 29.2656 41.6754 29.1554 41.6402C29.0452 41.6051 28.9431 41.5485 28.8548 41.4738C28.4059 41.1256 27.8884 40.8763 27.3363 40.7425C26.7842 40.6087 26.21 40.5933 25.6515 40.6974C25.2393 40.7384 24.8402 40.8645 24.4793 41.0677C24.1183 41.2708 23.8035 41.5467 23.5547 41.8779C23.4759 42.0064 23.3653 42.1125 23.2337 42.186C23.1021 42.2595 22.9537 42.298 22.803 42.2978Z" fill="#9B4E4E"/>
            <path d="M52.5557 42.2976C52.4049 42.2978 52.2566 42.2594 52.125 42.1858C51.9934 42.1123 51.8828 42.0062 51.804 41.8777C51.5552 41.5466 51.2404 41.2707 50.8794 41.0675C50.5185 40.8643 50.1194 40.7382 49.7072 40.6973C49.1487 40.5934 48.5746 40.6089 48.0225 40.7427C47.4704 40.8766 46.9529 41.1256 46.5039 41.4737C46.3256 41.6245 46.0946 41.6983 45.8618 41.6788C45.6291 41.6593 45.4136 41.5481 45.2628 41.3698C45.1119 41.1914 45.0382 40.9605 45.0576 40.7277C45.0771 40.4949 45.1883 40.2794 45.3666 40.1286C46.0097 39.6173 46.7542 39.2487 47.5507 39.0471C48.3471 38.8456 49.1774 38.8158 49.9862 38.9596C50.65 39.0359 51.2898 39.2537 51.8622 39.5982C52.4347 39.9428 52.9266 40.4061 53.3048 40.9569C53.3866 41.0903 53.4314 41.243 53.4346 41.3994C53.4378 41.5557 53.3993 41.7102 53.323 41.8467C53.2467 41.9832 53.1354 42.097 53.0005 42.1762C52.8657 42.2555 52.7121 42.2974 52.5557 42.2976Z" fill="#9B4E4E"/>
            <path d="M37.6882 52.5178C37.6635 52.5178 35.2851 52.479 35.2851 51.1005C35.2852 50.9125 35.3456 50.7294 35.4573 50.5781C35.569 50.4269 35.7263 50.3154 35.906 50.2599C36.0857 50.2045 36.2784 50.2081 36.456 50.2702C36.6335 50.3323 36.7864 50.4496 36.8924 50.605C37.1482 50.6923 37.4148 50.7436 37.6847 50.7572C37.9496 50.7438 38.2113 50.6928 38.4619 50.6058C38.5678 50.4502 38.7207 50.3325 38.8983 50.2702C39.0759 50.2078 39.2688 50.204 39.4487 50.2594C39.6286 50.3147 39.7861 50.4263 39.8979 50.5777C40.0098 50.7291 40.0701 50.9123 40.0702 51.1005C40.0737 52.479 37.712 52.5178 37.6882 52.5178Z" fill="#FF7364"/>
            <path d="M37.6882 59.1104C35.9075 59.1104 33.5748 58.9344 32.5748 58.1069C32.4859 58.0331 32.4123 57.9424 32.3584 57.8401C32.3045 57.7378 32.2713 57.6259 32.2606 57.5108C32.2499 57.3957 32.262 57.2796 32.2962 57.1691C32.3304 57.0587 32.3861 56.956 32.4599 56.8671C32.5338 56.7781 32.6245 56.7046 32.7267 56.6507C32.829 56.5968 32.9409 56.5635 33.0561 56.5529C33.1712 56.5422 33.2873 56.5543 33.3977 56.5885C33.5082 56.6227 33.6108 56.6783 33.6998 56.7522C33.9489 56.9591 35.1029 57.3499 37.6882 57.3499C40.2595 57.3499 41.41 56.9591 41.6591 56.7522C41.748 56.6783 41.8507 56.6226 41.9611 56.5883C42.0716 56.5541 42.1878 56.5419 42.3029 56.5526C42.4181 56.5632 42.53 56.5964 42.6324 56.6503C42.7347 56.7042 42.8254 56.7777 42.8994 56.8666C42.9733 56.9556 43.029 57.0582 43.0632 57.1687C43.0975 57.2792 43.1096 57.3953 43.099 57.5105C43.0884 57.6256 43.0552 57.7376 43.0013 57.8399C42.9474 57.9423 42.8739 58.033 42.7849 58.1069C41.7841 58.9361 39.4611 59.1104 37.6882 59.1104Z" fill="#FF7364"/>
            <path d="M49.2619 78.844C49.1484 78.8213 49.0316 78.8212 48.9181 78.8437C48.8046 78.8662 48.6967 78.9109 48.6004 78.9751C48.5042 79.0394 48.4216 79.122 48.3573 79.2182C48.293 79.3144 48.2484 79.4224 48.2259 79.5359C46.0613 90.4062 39.2947 94.4219 37.7305 95.2168C36.1601 94.4246 29.4121 90.4467 27.2378 79.5359C27.2152 79.4225 27.1705 79.3146 27.1062 79.2185C27.042 79.1224 26.9594 79.0399 26.8632 78.9757C26.767 78.9115 26.6592 78.8668 26.5458 78.8443C26.4323 78.8218 26.3156 78.8218 26.2022 78.8444C26.0888 78.867 25.981 78.9118 25.8848 78.976C25.7887 79.0403 25.7062 79.1229 25.642 79.219C25.5778 79.3152 25.5332 79.4231 25.5106 79.5365C25.4881 79.6499 25.4882 79.7667 25.5108 79.8801C28.1515 93.1174 37.0254 96.8523 37.4022 97.0055C37.4119 97.0055 37.4233 97.0055 37.4339 97.0117C37.5284 97.0479 37.6284 97.0675 37.7296 97.0698H37.7358C37.8367 97.0674 37.9364 97.0478 38.0307 97.0117C38.0412 97.0073 38.0527 97.0117 38.0633 97.0055C38.4391 96.8523 47.3157 93.1174 49.9529 79.8801C49.9756 79.7667 49.9758 79.6499 49.9533 79.5364C49.9309 79.423 49.8863 79.3151 49.8221 79.2188C49.7579 79.1226 49.6754 79.04 49.5793 78.9757C49.4832 78.9114 49.3753 78.8666 49.2619 78.844Z" fill="#473049"/>
            <path d="M18.7644 35.7222C19.7675 35.1114 20.862 34.6656 22.0064 34.4018C23.0895 34.1351 24.1908 33.9482 25.3012 33.8428C27.5036 33.6223 29.7234 33.6412 31.9217 33.8992C32.2158 33.9398 32.4821 34.0944 32.6634 34.3296C32.8446 34.5648 32.9261 34.8618 32.8905 35.1565C32.8548 35.4513 32.7047 35.7202 32.4726 35.9054C32.2405 36.0906 31.945 36.1772 31.6497 36.1465H31.6224H31.5968C29.5714 35.854 27.5203 35.78 25.479 35.9255C24.4675 35.9925 23.4623 36.1331 22.4712 36.3463C21.5326 36.5329 20.629 36.8656 19.7934 37.3322L19.7653 37.3489C19.5511 37.4756 19.2959 37.5132 19.0543 37.4538C18.8127 37.3944 18.604 37.2427 18.4731 37.0312C18.3421 36.8197 18.2992 36.5652 18.3537 36.3225C18.4082 36.0797 18.5556 35.868 18.7644 35.7327V35.7222Z" fill="#9B4E4E"/>
            <path d="M56.7 35.7222C55.697 35.1114 54.6024 34.6656 53.458 34.4018C52.3749 34.1351 51.2737 33.9482 50.1632 33.8428C47.9608 33.6223 45.741 33.6412 43.5427 33.8992C43.3933 33.9147 43.2484 33.9598 43.1166 34.0319C42.9847 34.104 42.8686 34.2017 42.7748 34.3191C42.6811 34.4366 42.6117 34.5715 42.5707 34.7161C42.5297 34.8607 42.5179 35.012 42.5359 35.1611C42.554 35.3103 42.6015 35.4544 42.6759 35.585C42.7502 35.7156 42.8498 35.8301 42.9688 35.9218C43.0878 36.0135 43.224 36.0806 43.3692 36.1192C43.5144 36.1578 43.6659 36.167 43.8147 36.1465H43.842H43.8676C45.893 35.854 47.9441 35.78 49.9854 35.9255C50.9969 35.9925 52.0022 36.1331 52.9932 36.3463C53.9318 36.5329 54.8354 36.8656 55.671 37.3322L55.6991 37.3489C55.9133 37.4756 56.1685 37.5132 56.4101 37.4538C56.6517 37.3944 56.8604 37.2427 56.9913 37.0312C57.1223 36.8197 57.1652 36.5652 57.1107 36.3225C57.0562 36.0797 56.9088 35.868 56.7 35.7327V35.7222Z" fill="#9B4E4E"/>
            <path d="M50.0654 49.7165C47.4 49.7165 44.6483 49.6478 43.5409 49.6179C42.7888 49.5934 42.068 49.3111 41.4994 48.8183C40.9307 48.3254 40.5488 47.652 40.4178 46.9111C40.1198 45.4308 39.9583 43.9263 39.9354 42.4165C39.9224 41.5647 40.2378 40.7405 40.8162 40.1151C41.3946 39.4897 42.1916 39.1109 43.0418 39.0574C47.0036 38.8223 50.9758 38.8223 54.9376 39.0574C55.785 39.1135 56.5783 39.4931 57.1537 40.1176C57.7291 40.7421 58.0425 41.5639 58.0291 42.4129C58.0137 43.9313 57.8504 45.4446 57.5414 46.9313C57.3922 47.654 57.01 48.3078 56.4535 48.7925C55.897 49.2771 55.1969 49.5658 54.4605 49.6143C53.2889 49.6909 51.693 49.7165 50.0654 49.7165ZM49.3321 40.6392C47.0435 40.6392 44.7029 40.7043 43.1703 40.8153C42.7606 40.836 42.3758 41.0183 42.1003 41.3222C41.8247 41.6262 41.6809 42.0269 41.7003 42.4367C41.724 43.8118 41.8713 45.182 42.1404 46.5308C42.1935 46.8856 42.367 47.2113 42.6318 47.4533C42.8966 47.6953 43.2365 47.8389 43.5946 47.86C47.2292 47.9595 51.9624 48.0131 54.3479 47.86C54.7111 47.8316 55.0546 47.6832 55.3244 47.4383C55.5941 47.1934 55.7748 46.8658 55.8381 46.507C56.1121 45.1675 56.2578 43.8048 56.273 42.4376C56.29 42.0257 56.1434 41.6238 55.8652 41.3195C55.587 41.0153 55.1998 40.8334 54.788 40.8135C53.4218 40.6929 51.3937 40.6401 49.3277 40.6401L49.3321 40.6392Z" fill="#EA3D5B"/>
            <path d="M25.2933 49.7171C23.6648 49.7171 22.0697 49.6916 20.9034 49.6168C20.1663 49.5692 19.4651 49.2809 18.9077 48.7962C18.3504 48.3115 17.9675 47.6572 17.8181 46.9338C17.5092 45.447 17.3459 43.9338 17.3304 42.4154C17.3171 41.5663 17.6305 40.7446 18.2058 40.12C18.7812 39.4955 19.5746 39.1159 20.4219 39.0598C24.3837 38.8248 28.3559 38.8248 32.3177 39.0598C33.1679 39.1133 33.9649 39.4921 34.5433 40.1175C35.1217 40.743 35.4372 41.5671 35.4242 42.4189C35.4012 43.9287 35.2397 45.4332 34.9418 46.9135C34.8107 47.6545 34.4288 48.3279 33.8602 48.8207C33.2915 49.3135 32.5707 49.5959 31.8186 49.6203C30.7104 49.6485 27.9578 49.7171 25.2933 49.7171ZM21.0152 47.8598C23.3989 48.0121 28.1339 47.9584 31.7684 47.8598C32.1265 47.8387 32.4665 47.6951 32.7313 47.4531C32.996 47.2111 33.1695 46.8854 33.2226 46.5306C33.4917 45.1818 33.639 43.8116 33.6628 42.4365C33.6822 42.0267 33.5383 41.626 33.2628 41.322C32.9872 41.0181 32.6024 40.8358 32.1927 40.8151C28.323 40.5857 24.443 40.5857 20.5733 40.8151C20.1615 40.8349 19.7743 41.0168 19.4961 41.3211C19.2179 41.6253 19.0713 42.0272 19.0883 42.4391C19.1035 43.8064 19.2491 45.169 19.5232 46.5086C19.5864 46.8674 19.7672 47.195 20.0369 47.4399C20.3067 47.6848 20.6502 47.8331 21.0134 47.8615L21.0152 47.8598Z" fill="#EA3D5B"/>
            <path d="M37.6793 101.17C39.1208 101.17 40.2893 100.001 40.2893 98.5599C40.2893 97.1185 39.1208 95.95 37.6793 95.95C36.2379 95.95 35.0693 97.1185 35.0693 98.5599C35.0693 100.001 36.2379 101.17 37.6793 101.17Z" fill="#F7F0DF"/>
            </svg>
        </div>
    )
}

export default StaffPersona
